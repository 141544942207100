import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { StandardGrid } from '../components/StandardGrid'
import { StyledHTMLContent } from '../components/StyledHTMLContent'

export type PageBodyCalloutBoxProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyCalloutBox = ({
	textHTML,
	image,
	imageAlt,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	nextIsFooter,
}: PageBodyCalloutBoxProps) => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		innerMaxWidthClassName="max-w-screen-md"
		ptClassName={clsx('pb-0', nextIsFooter && 'md:pb-13 lg:pb-16')}
		pbClassName="pb-0"
		className="mx-auto bg-white text-gray-10 max-w-screen-xl"
	>
		<div className="flex flex-col">
			<BoundedBox
				ptClassName="pt-8 md:pt-10 lg:pt-12"
				pbClassName="pb-8 md:pb-10 lg:pb-12"
				className={clsx(
					'-mx-6 bg-yellow-50 md:-mx-0 md:shadow-xl lg:shadow-2xl md:relative md:z-10 md:-mt-9 lg:-mt-12',
					!nextIsFooter && 'md:-mb-9 lg:-mb-12',
				)}
			>
				<StandardGrid>
					{image && (
						<div className="self-start border-white shadow border-6 md:border-8 col-span-5 md:col-span-4 -mt-13 md:-my-15 lg:-my-17">
							<GatsbyImage image={image} alt={imageAlt ?? ''} />
						</div>
					)}

					<div
						className={clsx(
							'self-center',
							image ? 'col-span-7 md:col-span-8' : 'col-span-full',
						)}
					>
						{textHTML && (
							<StyledHTMLContent
								html={textHTML}
								className={clsx(!image && 'text-center')}
							/>
						)}
					</div>
				</StandardGrid>
			</BoundedBox>
		</div>
	</BoundedBox>
)

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyCalloutBoxFragment,
	typeof mapDataToContext
>) => ({
	textHTML: data?.primary?.text?.html,
	image: getImage(data?.primary?.image),
	imageAlt: data?.primary?.image?.alt,
})

export const mapDataToContext = () => ({
	bg: ['bg-yellow-50', 'bg-white'],
	overhangsBottom: [false, true],
	overhangsTop: [false, true],
})

export const fragment = graphql`
	fragment PageBodyCalloutBox on PrismicPageDataBodyCalloutBox {
		primary {
			text {
				text
				html
			}
			image {
				alt
				gatsbyImageData(width: 500, placeholder: BLURRED)
			}
		}
	}
`

export default PageBodyCalloutBox
