import * as React from 'react'
import { HTMLContent, HTMLContentProps } from './HTMLContent'

type StyledHTMLContentProps<T extends React.ElementType> = HTMLContentProps<T>

export const StyledHTMLContent = <T extends React.ElementType>(
	props: StyledHTMLContentProps<T>,
) => (
	<HTMLContent
		{...props}
		componentOverrides={{
			h1: (Comp) => (props) =>
				<Comp className="text-red-50 contrast-more:text-red-30" {...props} />,
			h2: (Comp) => (props) =>
				<Comp className="text-teal-40 contrast-more:text-teal-20" {...props} />,
			h3: (Comp) => (props) =>
				<Comp className="text-red-50 contrast-more:text-red-30" {...props} />,
			...props.componentOverrides,
		}}
	/>
)
