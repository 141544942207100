import * as React from 'react'
import { graphql } from 'gatsby'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { StyledHTMLContent } from '../components/StyledHTMLContent'
import { ButtonLink } from '../components/ButtonLink'

export type PageBodyTextProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyText = ({
	textHTML,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	buttonHref,
	buttonText,
}: PageBodyTextProps) => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		innerMaxWidthClassName="max-w-screen-lg"
		className="bg-white text-gray-10 max-w-screen-xl mx-auto"
	>
		{textHTML && <StyledHTMLContent html={textHTML} />}
		{buttonHref && buttonText && (
			<ButtonLink className="mt-6" href={buttonHref}>
				{buttonText}
			</ButtonLink>
		)}
	</BoundedBox>
)

type PageBodyTextFragmentWithButton = Queries.PageBodyTextFragment & {
	primary?: {
		button_link?: string
		button_text?: string
	}
}

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	PageBodyTextFragmentWithButton,
	typeof mapDataToContext
>) => ({
	textHTML: data?.primary?.text?.html,
	buttonHref: data?.primary?.button_link,
	buttonText: data?.primary?.button_text,
})

export const mapDataToContext = () => ({
	bg: 'bg-white',
})

export const fragment = graphql`
	fragment PageBodyText on PrismicPageDataBodyText {
		primary {
			text {
				text
				html
			}
		}
	}
`

export default PageBodyText
