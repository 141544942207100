import * as React from 'react'
import clsx from 'clsx'
import { match, P } from 'ts-pattern'

import { ButtonLink } from './ButtonLink'
import { Icon } from './Icon'
import { Text } from './Text'
import { useUserLocation } from '../hooks/useUserLocation'
import { useLazyLocationFeatures } from '../hooks/useLazyLocationFeatures'
import { Button } from './Button'

type NearestLocationBoxProps = {
	className?: string
	backgroundClassName?: string
}

export const NearestLocationBox = ({
	className,
	backgroundClassName = 'bg-gray-95',
}: NearestLocationBoxProps) => {
	const lazyLocationFeatures = useLazyLocationFeatures()
	const userLocation = useUserLocation()
	const rId = React.useId()
	const id = 'location-order-now-btn-' + rId

	// We subtract 1 because we want to ensure we can always say "Over X locations"
	const roundedLocationsCount =
		Math.floor((lazyLocationFeatures.length - 1) / 50) * 50

	return (
		<div
			className={clsx(
				'p-4 lg:py-3 text-gray-10',
				backgroundClassName,
				className,
			)}
		>
			<div className="grid items-center h-full gap-4 justify-items-center lg:gap-3">
				<div className="grid gap-2">
					<div className="grid items-center justify-center grid-flow-col gap-1">
						<Icon name="pin" className="w-2 text-teal-40" />
						<Text
							variant="sans-10"
							className="font-extrabold tracking-widest uppercase text-red-50"
						>
							{match(userLocation.state)
								.with('resolved', () => 'Nearest Location')
								.otherwise(() => 'Find an L&L')}
						</Text>
					</div>

					<Text variant="sans-15-13" className="text-center">
						{match(userLocation)
							.with(
								{ state: 'resolved' },
								({ nearestLocation }) => nearestLocation.title,
							)
							.otherwise(() => `Over ${roundedLocationsCount} locations`)}
					</Text>
				</div>

				{match(userLocation)
					.with({ state: 'resolved' }, ({ nearestLocation }) => (
						<ButtonLink
							variant="yellowSmall"
							href={nearestLocation.loyaltyPlantUrl ?? nearestLocation.url}
							id={id}
						>
							Order Now
						</ButtonLink>
					))
					.with({ state: P.union('error', 'persmission-denied') }, () => (
						<ButtonLink variant="yellowSmall" href="/locations" id={id}>
							Order Now
						</ButtonLink>
					))
					.with({ state: 'resolving' }, () => (
						<Button variant="yellowSmall" disabled>
							Finding...
						</Button>
					))
					.otherwise(() => (
						<Button
							variant="yellowSmall"
							id={id}
							onClick={userLocation.request}
						>
							Find Location
						</Button>
					))}
			</div>
		</div>
	)
}
