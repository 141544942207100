import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'

import {
	MapDataToContextArgs,
	MapDataToPropsArgs,
} from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { ButtonLink } from '../components/ButtonLink'
import { StandardGrid } from '../components/StandardGrid'
import { FancyHeading } from '../components/FancyHeading'
import { Text } from '../components/Text'

const variants = {
	Red: {
		backgroundColorClassName: 'bg-red-50 contrast-more:bg-red-30',
		imageBackgroundColorClassName: 'bg-red-30',
		subheadingColorClassName: 'text-yellow-50',
		fancyHeadingVariant: 'yellowXSmall',
		buttonVariant: 'yellowLarge',
		hoverColorClassName: 'hover:underline',
		colorClassName:
			'text-teal-40 contrast-more:text-teal-50 contrast-more:underline',
	},
	Teal: {
		backgroundColorClassName: 'bg-teal-40',
		imageBackgroundColorClassName: 'bg-teal-20',
		subheadingColorClassName: 'text-yellow-50',
		fancyHeadingVariant: 'yellowXSmall',
		buttonVariant: 'yellowLarge',
		hoverColorClassName: 'hover:text-red-50',
		colorClassName:
			'text-teal-40 contrast-more:text-teal-30 contrast-more:underline',
	},
} as const

const defaultVariant = 'Red'

export type PageBodyLargePromoProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyLargePromo = ({
	variant: variantName = defaultVariant,
	image,
	imageAlt,
	textHTML,
	buttonText = 'Learn more',
	buttonHref,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodyLargePromoProps): JSX.Element => {
	const variant = variants[variantName]

	return (
		<BoundedBox
			as="section"
			id={id}
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			className={clsx(
				'text-white max-w-screen-xl mx-auto relative',
				variant.backgroundColorClassName,
			)}
		>
			<div
				className={clsx(
					'-mx-6 -mt-10 md:absolute md:top-0 md:left-0 md:bottom-0 md:w-1/2 md:mx-0 md:mt-0',
					variant.imageBackgroundColorClassName,
				)}
			>
				<div className="h-full overflow-hidden aspect-w-4 aspect-h-3 md:aspect-w-7 md:aspect-h-4">
					{image && (
						<div>
							<GatsbyImage
								image={image}
								alt={imageAlt ?? ''}
								className="h-full"
							/>
						</div>
					)}
				</div>
			</div>

			<StandardGrid className="md:min-h-27rem">
				<div className="grid content-center gap-8 pt-10 col-span-full md:gap-10 lg:gap-12 justify-items-center md:pt-0 md:col-span-6 md:col-start-7 md:justify-items-start md:pl-4 lg:pl-8 xl:pl-20 md:max-w-45rem">
					{textHTML && (
						<HTMLContent
							html={textHTML}
							className="text-center md:text-left"
							componentOverrides={{
								h1: () => (props) =>
									(
										<FancyHeading
											as="h2"
											variant={variant.fancyHeadingVariant}
											className="mb-7 md:mb-8 last:mb-0"
											{...props}
										/>
									),
								h2: () => (props) =>
									(
										<Text
											variant="sans-24"
											{...props}
											className={clsx(
												'font-bold mb-7 md:mb-8 last:mb-0',
												variant.subheadingColorClassName,
											)}
										/>
									),
								a: (Comp) => (props) =>
									(
										<Comp
											{...props}
											colorClassName={variant.colorClassName}
											hoverColorClassName={variant.hoverColorClassName}
										/>
									),
							}}
						/>
					)}
					{buttonHref && (
						<ButtonLink href={buttonHref} variant={variant.buttonVariant}>
							{buttonText}
						</ButtonLink>
					)}
				</div>
			</StandardGrid>
		</BoundedBox>
	)
}

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyLargePromoFragment,
	typeof mapDataToContext
>) => ({
	variant: data.primary?.variant as keyof typeof variants | undefined,
	image: getImage(data.primary?.image),
	imageAlt: data.primary?.image?.alt,
	textHTML: data?.primary?.text?.html,
	buttonText: data.primary?.button_text?.text,
	buttonHref: data.primary?.button_link?.url,
})

export const mapDataToContext = ({
	data,
}: MapDataToContextArgs<Queries.PageBodyLargePromoFragment>) => {
	const variant =
		variants[(data.primary?.variant as keyof typeof variants) ?? defaultVariant]

	return {
		bgTop: Symbol(),
		bgBottom: [variant.backgroundColorClassName, Symbol()],
	}
}

export const fragment = graphql`
	fragment PageBodyLargePromo on PrismicPageDataBodyLargePromo {
		primary {
			variant
			image {
				alt
				gatsbyImageData(
					width: 1000
					placeholder: BLURRED
					layout: FULL_WIDTH
					sizes: "(min-width: 48rem) 50vw, 100vw"
				)
			}
			text {
				text
				html
			}
			button_link {
				url
			}
			button_text {
				text
			}
		}
	}
`

export default PageBodyLargePromo
