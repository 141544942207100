import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import clsx from 'clsx'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'
import { BoundedBox } from '../components/BoundedBox'
import { VideoPlayer } from '../components/VideoPlayer'

export type PageBodyHeroFloatingImageProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyHeroFloatingImage = ({
	videoUrl,
	image,
	imageAlt,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	nextIsFooter,
}: PageBodyHeroFloatingImageProps) => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		innerMaxWidthClassName="max-w-70rem"
		plClassName="pl-4 md:pl-8 lg:pl-10"
		prClassName="pr-4 md:pr-8 lg:pr-10"
		pbClassName={nextIsFooter ? undefined : 'pb-0'}
		className="relative flex max-w-screen-xl mx-auto bg-yellow-50"
	>
		<div className="absolute inset-0 overflow-hidden pointer-events-none select-none">
			<StaticImage
				src="../../static/images/texture-dots-surfboards.png"
				alt=""
				className="w-full h-full"
				placeholder="blurred"
				objectFit="cover"
				quality={50}
				sizes="100vw"
				layout="fullWidth"
				formats={['avif', 'webp']}
			/>
		</div>

		<div className="absolute inset-0 overflow-hidden pointer-events-none select-none">
			<div className="max-w-screen-lg mx-auto">
				<div className="relative aspect-w-4 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
					<div>
						<div className="absolute top-0 left-0 transform w-24rem md:w-36rem lg:w-46rem -translate-x-1/3 -translate-y-1/3 lg:-translate-x-1/2">
							<StaticImage
								src="../../static/images/texture-starburst.png"
								alt=""
								quality={50}
								layout="fullWidth"
								placeholder="none"
								formats={['avif', 'webp']}
								sizes="60vw"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			className={clsx(
				'relative',
				!nextIsFooter && 'z-10 -mb-5 md:-mb-9 lg:-mb-12',
			)}
		>
			<VideoPlayer
				url={videoUrl}
				posterImage={image}
				posterImageAlt={imageAlt}
				className={clsx(
					'aspect-w-16 aspect-h-9',
					'shadow-lg md:shadow-xl lg:shadow-2xl bg-yellow-40',
				)}
				autoplay
			/>
		</div>
	</BoundedBox>
)

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyHeroFloatingImageFragment,
	typeof mapDataToContext
>) => ({
	image: getImage(data.primary?.image),
	imageAlt: data.primary?.image?.alt,
	videoUrl: data.primary.video_url?.embed_url,
})

export const mapDataToContext = () => ({
	bg: Symbol(),
	overhangsBottom: true,
})

export const fragment = graphql`
	fragment PageBodyHeroFloatingImage on PrismicPageDataBodyHeroFloatingImage {
		primary {
			video_url {
				embed_url
			}
			image {
				alt
				gatsbyImageData(
					width: 1000
					layout: FULL_WIDTH
					placeholder: BLURRED
					sizes: "(min-width: 64rem) 70rem, 44rem"
				)
			}
		}
	}
`

export default PageBodyHeroFloatingImage
