import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'

export type PageBodyLLHeroProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyLLHero = ({
	textHTML,
	introImage,
	introImageAlt,
	secondaryImage,
	secondaryImageAlt,
	backgroundImage,
	backgroundImageAlt,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodyLLHeroProps) => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		innerMaxWidthClassName="max-w-screen-lg"
		ptClassName={secondaryImage ? 'pt-12 md:pt-17 lg:pt-22' : undefined}
		className="relative mx-auto bg-green-50 max-w-screen-xl"
	>
		{backgroundImage && (
			<div className="absolute inset-0 overflow-hidden pointer-events-none select-none">
				<GatsbyImage
					image={backgroundImage}
					alt={backgroundImageAlt ?? ''}
					objectPosition={secondaryImage ? 'bottom center' : 'top center'}
					className="w-full h-full"
				/>
			</div>
		)}

		<div className="relative grid gap-8 lg:gap-12">
			<div className="w-full mx-auto grid gap-8 lg:gap-12 max-w-screen-md">
				{introImage && (
					<GatsbyImage
						image={introImage}
						alt={introImageAlt ?? ''}
						objectFit="contain"
						className="w-full mx-auto max-w-19rem md:max-w-19rem lg:max-w-36rem"
					/>
				)}

				{textHTML && (
					<HTMLContent html={textHTML} className="text-center text-white" />
				)}
			</div>

			{secondaryImage && (
				<div className="relative w-full mx-auto max-w-screen-sm md:max-w-none">
					<div className="absolute left-0 bottom-12 md:bottom-6 transform -translate-x-1/4 lg:-translate-x-1/2 lg:bottom-12">
						<StaticImage
							src="../../static/images/hula-girl-1.png"
							alt="Hula girl dancing"
							className="w-23 md:w-12rem lg:w-19rem"
							width={885}
							placeholder="blurred"
							formats={['avif', 'webp']}
							quality={50}
						/>
					</div>

					<div className="absolute right-0 bottom-13 md:bottom-6 md:block lg:transform lg:translate-x-1/3 lg:bottom-18">
						<StaticImage
							src="../../static/images/hula-girl-2.png"
							alt="Hula girl playing an ukulele"
							className="w-20 md:w-10rem lg:w-17rem"
							width={539}
							placeholder="blurred"
							formats={['avif', 'webp']}
							quality={50}
						/>
					</div>

					<GatsbyImage
						image={secondaryImage}
						alt={secondaryImageAlt ?? ''}
						objectFit="contain"
						className="w-full mx-auto max-w-40rem lg:max-w-52rem"
					/>
				</div>
			)}
		</div>
	</BoundedBox>
)

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyLLHeroFragment,
	typeof mapDataToContext
>) => ({
	textHTML: data.primary?.text?.html,
	introImage: getImage(data.primary?.intro_image),
	introImageAlt: data.primary?.intro_image?.alt,
	secondaryImage: getImage(data.primary?.secondary_image),
	secondaryImageAlt: data.primary?.secondary_image?.alt,
	backgroundImage: getImage(data.primary?.background_image),
	backgroundImageAlt: data.primary?.background_image?.alt,
})

export const mapDataToContext = () => ({
	bg: Symbol(),
})

export const fragment = graphql`
	fragment PageBodyLLHero on PrismicPageDataBodyLLHero {
		primary {
			intro_image {
				alt
				gatsbyImageData(width: 700)
			}
			text {
				text
				html
			}
			secondary_image {
				alt
				gatsbyImageData(
					width: 700
					layout: FULL_WIDTH
					placeholder: BLURRED
					sizes: "(min-width: 64rem) 56rem, 100vw"
				)
			}
			background_image {
				alt
				gatsbyImageData(
					width: 1000
					layout: FULL_WIDTH
					placeholder: BLURRED
					sizes: "(min-width: 120rem) 120rem, 100vw"
				)
			}
		}
	}
`

export default PageBodyLLHero
