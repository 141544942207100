import * as React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'
import { BoundedBox } from '../components/BoundedBox'
import { Text } from '../components/Text'
import { ButtonLink } from '../components/ButtonLink'
import { PlateSlider } from '../components/PlateSlider'

export type PageBodyHeroPlatesProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyHeroPlates = ({
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	heading,
	buttonHref,
	buttonText,
	plates,
}: PageBodyHeroPlatesProps): JSX.Element => {
	return (
		<BoundedBox
			as="section"
			id={id}
			variant="wide"
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			innerMaxWidthClassName="max-w-90rem"
			className="max-w-screen-xl mx-auto relative bg-yellow-50 z-10"
		>
			{heading && (
				<Text
					as="h1"
					variant="sans-48-64"
					className="text-red-50 font-extrabold relative z-10 max-w-[24ch]"
				>
					{heading}
				</Text>
			)}

			{plates && <PlateSlider plates={plates} className="mt-8 lg:mt-16" />}

			{buttonHref && buttonText && (
				<ButtonLink href={buttonHref} className="mt-3 relative lg:mt-5">
					{buttonText}
				</ButtonLink>
			)}
		</BoundedBox>
	)
}

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyHeroPlatesFragment,
	typeof mapDataToContext
>) => {
	return {
		heading: data.primary?.heading?.text,
		buttonText: data.primary?.button_text?.text,
		buttonHref: data.primary?.button_link?.url,
		plates: data.items
			?.map((item) => {
				const menuItemDoc = item.menu_item?.document
				if (!menuItemDoc || !('url' in menuItemDoc)) return undefined!

				return {
					image: getImage(item?.image),
					imageAlt: item?.image?.alt,
					textHTML: menuItemDoc.data.description?.html,
					title: menuItemDoc?.data?.title?.text,
					href: menuItemDoc?.url,
				}
			})
			.filter(Boolean),
	}
}

export const mapDataToContext = () => ({
	bg: 'bg-yellow-50',
	overhangsBottom: true,
})

export const fragment = graphql`
	fragment PageBodyHeroPlates on PrismicPageDataBodyHeroPlates {
		primary {
			heading {
				text
			}
			button_text {
				text
			}
			button_link {
				url
			}
		}

		items {
			menu_item {
				document {
					... on PrismicMenuItem {
						url
						data {
							description {
								html
								text
							}
							title {
								text
							}
						}
					}
				}
			}
			image {
				alt
				gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
			}
		}
	}
`

export default PageBodyHeroPlates
