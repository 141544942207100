import * as React from 'react'
import { graphql } from 'gatsby'
import ConditionalWrap from 'conditional-wrap'
import {
	GatsbyImage,
	getImage,
	IGatsbyImageData,
	StaticImage,
} from 'gatsby-plugin-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { Link } from '../components/Link'

import AssetTextureRoughPaper from '../assets/texture-rough-paper.jpg'

export type PageBodyLLPartnersProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyLLPartners = ({
	textHTML,
	children,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodyLLPartnersProps) => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		innerMaxWidthClassName="max-w-screen-lg"
		className="relative mx-auto overflow-hidden bg-repeat-y bg-w-full white text-gray-10 max-w-screen-xl"
		style={{ backgroundImage: `url('${AssetTextureRoughPaper}')` }}
	>
		<div className="relative">
			<div className="absolute bottom-0 left-0 hidden md:block transform translate-y-1/2 lg:-translate-x-2/3">
				<StaticImage
					src="../../static/images/hula-girl-1.png"
					alt="Hula girl dancing"
					className="w-23 md:w-12rem lg:w-15rem"
					width={885}
					placeholder="blurred"
					formats={['avif', 'webp']}
					quality={50}
				/>
			</div>

			<div className="absolute bottom-0 right-0 hidden md:block transform translate-y-1/2 lg:translate-x-1/2">
				<StaticImage
					src="../../static/images/hula-girl-2.png"
					alt="Hula girl playing an ukulele"
					className="w-20 md:w-10rem lg:w-12rem"
					width={539}
					placeholder="blurred"
					formats={['avif', 'webp']}
					quality={50}
				/>
			</div>

			<div className="grid gap-8 lg:gap-10">
				{textHTML && (
					<HTMLContent
						html={textHTML}
						componentOverrides={{
							h1: (Comp) => (props) => (
								<Comp className="text-green-50" {...props} />
							),
							p: (Comp) => (props) => <Comp variant="sans-16" {...props} />,
						}}
						className="w-full mx-auto text-center max-w-screen-md"
					/>
				)}

				<ul className="flex flex-col justify-center -mt-6 -ml-10 md:flex-wrap items-center lg:flex-row">
					{children}
				</ul>
			</div>
		</div>
	</BoundedBox>
)

type PartnerProps = {
	logoImage?: IGatsbyImageData
	logoImageAlt?: string | null
	href?: string | null
}

const Partner = ({ logoImage, logoImageAlt, href }: PartnerProps) =>
	logoImage ? (
		<li className="pt-6 pl-10">
			<ConditionalWrap
				condition={Boolean(href)}
				wrap={(children) => <Link href={href!}>{children}</Link>}
			>
				<GatsbyImage
					image={logoImage}
					alt={logoImageAlt ?? ''}
					className="w-12rem"
					objectFit="contain"
				/>
			</ConditionalWrap>
		</li>
	) : null

PageBodyLLPartners.Partner = Partner

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyLLPartnersFragment,
	typeof mapDataToContext
>) => ({
	textHTML: data.primary?.text?.html,
	children: data.items?.map((item) => (
		<PageBodyLLPartners.Partner
			key={item?.partner_link?.url}
			logoImage={getImage(item?.partner_logo)}
			logoImageAlt={item?.partner_logo?.alt ?? undefined}
			href={item?.partner_link?.url}
		/>
	)) as React.ReactNode,
})

export const mapDataToContext = () => ({
	bg: Symbol(),
})

export const fragment = graphql`
	fragment PageBodyLLPartners on PrismicPageDataBodyLLPartners {
		primary {
			text {
				text
				html
			}
		}
		items {
			partner_logo {
				alt
				gatsbyImageData(width: 200)
			}
			partner_link {
				url
			}
		}
	}
`

export default PageBodyLLPartners
