import * as React from 'react'
import { graphql } from 'gatsby'
import clsx from 'clsx'
import { VisuallyHidden } from '@reach/visually-hidden'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { Text } from '../components/Text'
import { HTMLContent } from '../components/HTMLContent'
import { ButtonLink } from '../components/ButtonLink'

export type PageBodyPromosProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyPromos = ({
	textHTML,
	children,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	nextIsFooter,
}: PageBodyPromosProps): JSX.Element => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		innerMaxWidthClassName="max-w-70rem"
		ptClassName="pt-10 md:pt-0"
		pbClassName={nextIsFooter ? undefined : 'pb-0 md:pb-13 lg:pb-16'}
		className="max-w-screen-xl mx-auto text-white bg-white"
	>
		<div className="grid gap-8 md:gap-12 lg:gap-14">
			{textHTML && (
				<HTMLContent
					html={textHTML}
					componentOverrides={{
						h1: (Comp) => (props) => (
							<Comp as="h1" className="uppercase text-yellow-50" {...props} />
						),
					}}
					className="text-center md:row-start-2"
				/>
			)}

			<ul
				className={clsx(
					'relative z-10 flex flex-col shadow-lg md:-mt-9 lg:-mt-12 md:shadow-xl lg:shadow-2xl md:row-start-1 md:flex-row',
					!nextIsFooter && '-mb-5 md:-mb-0',
				)}
			>
				{children}
			</ul>
		</div>
	</BoundedBox>
)

const promoVariants = {
	white: {
		backgroundClassName: 'bg-white',
		imageBackgroundClassName: 'bg-gray-80',
		colorClassName: 'text-gray-10',
		h1ColorClassName: 'text-red-50',
		h2ColorClassName: 'text-teal-40',
	},
	red: {
		backgroundClassName: 'bg-red-50',
		imageBackgroundClassName: 'bg-red-30',
		colorClassName: 'text-white',
		h1ColorClassName: 'text-yellow-50',
		h2ColorClassName: 'white',
	},
}

type PromoProps = {
	variant: keyof typeof promoVariants
	textHTML?: string | null
	image?: IGatsbyImageData
	imageAlt?: string | null
	buttonText?: string | null
	buttonHref?: string | null
	buttonTarget?: string | null
}

const Promo = ({
	variant: variantName,
	textHTML,
	image,
	imageAlt,
	buttonText = 'Learn More',
	buttonHref,
	buttonTarget,
}: PromoProps) => {
	const variant = promoVariants[variantName]

	return (
		<li className="flex flex-col md:flex-1">
			{image && (
				<div className="overflow-hidden max-h-24rem">
					<div
						className={clsx(
							'aspect-w-8 aspect-h-5',
							variant.imageBackgroundClassName,
						)}
					>
						<div>
							<GatsbyImage
								image={image}
								alt={imageAlt ?? ''}
								className="h-full"
							/>
						</div>
					</div>
				</div>
			)}

			<BoundedBox
				className={clsx(
					'flex-grow',
					variant.backgroundClassName,
					variant.colorClassName,
				)}
			>
				<div className="grid gap-7 md:gap-8 justify-items-center">
					{textHTML && (
						<HTMLContent
							html={textHTML}
							componentOverrides={{
								h1: () => (props) => (
									<Text
										as="h2"
										variant="sans-40"
										className={clsx(
											'uppercase mb-5 last:mb-0 font-extrabold',
											variant.h1ColorClassName,
										)}
										{...props}
									/>
								),
								h2: () => (props) => (
									<Text
										as="h2"
										variant="sans-24"
										className={clsx(
											'mb-5 font-bold last:mb-0',
											variant.h2ColorClassName,
										)}
										{...props}
									/>
								),
							}}
							className="text-center"
						/>
					)}

					{buttonHref && (
						<ButtonLink
							variant="yellowLarge"
							href={buttonHref}
							target={buttonTarget ?? undefined}
						>
							<VisuallyHidden>
								Learn more by visiting {buttonHref}
							</VisuallyHidden>
							{buttonText}
						</ButtonLink>
					)}
				</div>
			</BoundedBox>
		</li>
	)
}

PageBodyPromos.Promo = Promo

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyPromosFragment,
	typeof mapDataToContext
>) => ({
	textHTML: data.primary?.text?.html,
	children: data.items?.map((item, index) => (
		<Promo
			key={item?.text?.text}
			variant={index % 2 ? 'red' : 'white'}
			textHTML={item.text?.html}
			image={getImage(item.image)}
			imageAlt={item?.image?.alt}
			buttonText={item?.button_text?.text}
			buttonHref={item?.button_link?.url}
			buttonTarget={item?.button_link?.target}
		/>
	)) as React.ReactNode | undefined,
})

export const mapDataToContext = () => ({
	bg: 'bg-teal-40',
	overhangsBottom: [true, false],
	overhangsTop: [false, true],
})

export const fragment = graphql`
	fragment PageBodyPromos on PrismicPageDataBodyPromos {
		primary {
			text {
				text
				html
			}
		}
		items {
			image {
				alt
				gatsbyImageData(
					width: 500
					placeholder: BLURRED
					sizes: "(min-width: 64rem) 35rem, (min-width: 48rem) calc(50vw - 2rem), calc(100vw - 3rem)"
					layout: FULL_WIDTH
				)
			}
			text {
				text
				html
			}
			button_text {
				text
			}
			button_link {
				url
				target
			}
		}
	}
`

export default PageBodyPromos
