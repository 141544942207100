import { useStaticQuery, graphql } from 'gatsby'

export const useSearchIndex = () => {
	const data = useStaticQuery<Queries.SearchIndexQuery>(graphql`
		query SearchIndex {
			prismicSearchIndex {
				indexURL
			}
		}
	`)

	return {
		indexURL: data.prismicSearchIndex?.indexURL ?? '',
	}
}
