import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'

export type PageBodyFeaturedImageProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyFeaturedImage = ({
	textHTML,
	image,
	imageAlt,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodyFeaturedImageProps) => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		innerMaxWidthClassName="max-w-screen-lg"
		ptClassName="pt-0"
		className="mx-auto text-white max-w-screen-xl bg-teal-40"
	>
		<div className="grid gap-7 md:gap-8 lg:gap-10">
			<div className="relative z-10 -mt-5 md:-mt-9 lg:-mt-12 shadow-lg md:shadow-xl lg:shadow-2xl">
				<div className="aspect-w-4 aspect-h-3 md:aspect-w-9 md:aspect-h-5 bg-yellow-50">
					<div>
						{image && (
							<GatsbyImage
								image={image}
								alt={imageAlt ?? ''}
								className="w-full h-full"
							/>
						)}
					</div>
				</div>
			</div>

			{textHTML && (
				<HTMLContent
					html={textHTML}
					componentOverrides={{
						h1: (Comp) => (props) => (
							<Comp
								as="h1"
								className="tracking-wide uppercase text-yellow-50"
								{...props}
							/>
						),
						h2: (Comp) => (props) => (
							<Comp as="p" className="tracking-wide uppercase" {...props} />
						),
					}}
					className="mx-auto text-center max-w-screen-md"
				/>
			)}
		</div>
	</BoundedBox>
)

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyFeaturedImageFragment,
	typeof mapDataToContext
>) => ({
	textHTML: data?.primary?.text?.html,
	image: getImage(data.primary?.image),
	imageAlt: data.primary?.image?.alt,
})

export const mapDataToContext = () => ({
	bg: 'bg-teal-40',
	overhangsTop: true,
})

export const fragment = graphql`
	fragment PageBodyFeaturedImage on PrismicPageDataBodyFeaturedImage {
		primary {
			image {
				alt
				gatsbyImageData(
					width: 1000
					placeholder: BLURRED
					layout: FULL_WIDTH
					sizes: "(min-width: 64rem) 63rem, (min-width: 48rem) calc(100vw - 4rem), calc(100vw - 3rem)"
				)
			}
			text {
				text
				html
			}
		}
	}
`

export default PageBodyFeaturedImage
