import { useStaticQuery, graphql } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

export const useNotificationMessage = () => {
	const staticData =
		useStaticQuery<Queries.UseNotificationMessageQuery>(graphql`
			query UseNotificationMessage {
				prismicNotification {
					_previewable
					data {
						text {
							text
							html
						}
						is_active
					}
				}
			}
		`)
	const data = useMergePrismicPreviewData(staticData)

	const notification = data?.prismicNotification?.data

	return {
		text: notification?.text?.text,
		textHTML: notification?.text?.html,
		isActive: notification?.is_active,
	}
}
