import * as React from 'react'

import { Button, ButtonProps } from './Button'
import { Link } from './Link'

type ButtonLinkProps = ButtonProps<'a'>

export const ButtonLink = (props: ButtonLinkProps) => (
  <Button as={Link} {...props} />
)
