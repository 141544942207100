import { GatsbyBrowser } from 'gatsby'
import './src/global'

export { wrapRootElement } from './src/global'

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
	if (location.pathname === '/catering/') {
		// @ts-expect-error
		window.dataLayer?.push({ event: 'gatsby-route-change-catering' })
	}
}
