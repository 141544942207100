import * as React from 'react'
import { SkipNavLink } from '@reach/skip-nav'

import { Text } from './Text'
import { DevPanel } from './DevPanel'

export type LayoutProps = {
	children?: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
	return (
		<>
			<SkipNavLink>
				<Text variant="sans-18" className="font-bold">
					Skip to content
				</Text>
			</SkipNavLink>

			<main className="text-gray-10">{children}</main>

			{process.env.NODE_ENV === 'development' && <DevPanel />}
		</>
	)
}
