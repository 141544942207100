/**
 * Returns an insecure, psuedo-random hash number from a provided string.
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export function hash(str: string): number {
	let hash = 0

	for (let i = 0, len = str.length; i < len; i++) {
		let chr = str.charCodeAt(i)
		hash = (hash << 5) - hash + chr
		hash = hash & hash
	}

	return Math.abs(hash)
}
