import * as React from 'react'
import { point, Feature, Point } from '@turf/helpers'
import { useStaticQuery, graphql, withPrefix } from 'gatsby'

interface LazyLocationFeatureProperties {
	lazyLocationURL: string
}

export type LazyLocationFeature = Feature<Point, LazyLocationFeatureProperties>

export const useLazyLocationFeatures = (): LazyLocationFeature[] => {
	const queryData = useStaticQuery<Queries.UseLazyLocationFeaturesQuery>(graphql`
		query UseLazyLocationFeatures {
			allPrismicLocation {
				nodes {
					internal {
						contentDigest
					}
					data {
						coordinates {
							longitude
							latitude
						}
						loyaltyplant_url {
							url
						}
					}
				}
			}
		}
	`)

	const locations = queryData.allPrismicLocation.nodes

	return React.useMemo(() => {
		const features = []

		for (const location of locations) {
			if (
				location.data?.coordinates?.longitude &&
				location.data?.coordinates?.latitude
			) {
				features.push(
					point(
						[
							location.data?.coordinates?.longitude,
							location.data?.coordinates?.latitude,
						],
						{
							lazyLocationURL: withPrefix(
								`/static/${location.internal.contentDigest}.json`,
							),
						},
					),
				)
			}
		}

		return features
	}, [locations])
}
