import * as React from 'react'

/**
 * Loads an external script using a `<script>` element.
 *
 * @param src - URL to the external script.
 */
export const useExternalScript = (src?: string) => {
	const [isLoaded, setIsLoaded] = React.useState(false)
	const id = React.useId()

	React.useEffect(() => {
		if (id && src) {
			const script = document.createElement('script')
			script.src = src
			script.id = id

			script.onload = () => setIsLoaded(true)

			document.body.appendChild(script)
		}
	}, [id, src])

	return isLoaded
}
