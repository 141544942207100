type BuildGoogleMapsSearchURLArgs = {
	streetAddress?: string | null
	state?: string | null
	city?: string | null
	zipCode?: string | null
	country?: string | null
}

export const buildGoogleMapsSearchURL = ({
	streetAddress,
	state,
	city,
	zipCode,
	country,
}: BuildGoogleMapsSearchURLArgs) =>
	`http://maps.google.com/?q=${encodeURIComponent(
		[streetAddress, state, city, zipCode, country].filter(Boolean).join(' '),
	)}`
