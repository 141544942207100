import * as React from 'react'

import { useDebugController } from '../hooks/useDebug'

import { ReactComponent as AssetIconRefreshSVG } from '../assets/_icon-refresh.svg'
import { ReactComponent as AssetIconDebugSVG } from '../assets/_icon-debug.svg'
import { ReactComponent as AssetIconEditSVG } from '../assets/_icon-edit.svg'
import { ReactComponent as AssetIconGraphQLSVG } from '../assets/_icon-graphql.svg'
import { ReactComponent as AssetIconHelpSVG } from '../assets/_icon-help.svg'

import './DevPanel.css'

const refreshData = () => fetch('/__refresh', { method: 'post' })
const openAdmin = () => window.open('/admin')
const openDocs = () => window.open('/docs')
const openGraphiQL = () => window.open('/__graphql')

const icons = {
	refresh: AssetIconRefreshSVG,
	debug: AssetIconDebugSVG,
	edit: AssetIconEditSVG,
	graphql: AssetIconGraphQLSVG,
	help: AssetIconHelpSVG,
} as const

type DevButtonProps = {
	icon: keyof typeof icons
	onClick: () => void
	isActive?: boolean
	title: string
	className?: string
}

const DevButton = ({
	icon: iconName,
	onClick,
	isActive = false,
	title,
}: DevButtonProps) => {
	const IconSVG = icons[iconName]

	return (
		<button
			tabIndex={-1}
			onClick={onClick}
			title={title}
			data-dev-panel-button=""
			data-active={isActive.toString()}
		>
			<IconSVG data-dev-panel-icon />
		</button>
	)
}

export const DevPanel = () => {
	const [debug, toggleDebug] = useDebugController()

	return (
		<div data-dev-panel>
			<DevButton onClick={openAdmin} icon="edit" title="Open admin" />
			<DevButton onClick={openGraphiQL} icon="graphql" title="Open GraphiQL" />
			<DevButton onClick={openDocs} icon="help" title="Open docs" />
			<DevButton onClick={refreshData} icon="refresh" title="Refresh data" />
			<DevButton
				onClick={toggleDebug}
				icon="debug"
				isActive={debug}
				title={debug ? 'Disable debug mode' : 'Enable debug mode'}
			/>
		</div>
	)
}
