import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { FancyHeading } from '../components/FancyHeading'
import { Text } from '../components/Text'

const PRIMARY_DESCRIPTIONS = [
	{ description: 'Two servings of rice', top: '55%', left: '42%' },
	{ description: 'One serving macaroni salad', top: '20%', left: '42%' },
]

const SECONDARY_DESCRIPTIONS = [
	{ description: 'A local, fresh catch', top: '45%', left: '28%' },
	{ description: 'A chicken entrée', top: '45%', left: '66%' },
	{ description: 'An alternate protein', top: '49%', left: '14%' },
]

type DescriptionNumberProps = {
	index: number
	filled?: boolean
	withActiveAnimation?: boolean
	isActive?: boolean
	activate?: () => void
	deactivate?: () => void
	style?: React.CSSProperties
	className?: string
}

const DescriptionNumber = ({
	index,
	filled = false,
	withActiveAnimation = false,
	isActive = false,
	activate,
	deactivate,
	className,
	style,
}: DescriptionNumberProps) => {
	const Element = activate || deactivate ? 'button' : 'div'

	return (
		<Element
			tabIndex={-1}
			onFocus={activate}
			onMouseOver={activate}
			onClick={activate}
			onBlur={deactivate}
			onMouseOut={deactivate}
			className={clsx(
				'text-white flex items-center justify-center w-10 h-10 border-2 border-white rounded-full lg:w-12 lg:h-12 md:border-3 transition transform',
				filled && !isActive && 'bg-red-50',
				isActive && 'bg-teal-40',
				withActiveAnimation && isActive && 'scale-125',
				className,
			)}
			style={style}
		>
			<Text variant="sans-18-24" className="font-bold uppercase center">
				{index + 1}
			</Text>
		</Element>
	)
}

type DescriptionProps = {
	index: number
	description: string
	isActive?: boolean
	activate?: () => void
	deactivate?: () => void
}

const Description = ({
	index,
	description,
	isActive = false,
	activate,
	deactivate,
}: DescriptionProps) => {
	return (
		<button
			onFocus={activate}
			onMouseOver={activate}
			onClick={activate}
			onBlur={deactivate}
			onMouseOut={deactivate}
		>
			<div className="items-center justify-start text-left grid gap-3 md:gap-4 grid-flow-col">
				<DescriptionNumber index={index} isActive={isActive} />
				<Text
					variant="sans-18-24"
					className="font-bold tracking-wide uppercase max-w-20ch"
				>
					{description}
				</Text>
			</div>
		</button>
	)
}

export type PageBodyPlateLunchDiagramProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyPlateLunchDiagram = ({
	textHTML,
	introTextHTML,
	plateLunchImage,
	plateLunchImageAlt,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodyPlateLunchDiagramProps) => {
	const [
		activeDescriptionIndex,
		setActiveDescriptionIndex,
	] = React.useState<number>()

	return (
		<BoundedBox
			as="section"
			id={id}
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			innerMaxWidthClassName="max-w-70rem"
			className="mx-auto text-white bg-center bg-red-50 max-w-screen-xl bg-pattern-red bg-w-80rem"
		>
			<div className="grid gap-8 md:gap-10 lg:gap-12">
				{introTextHTML && (
					<HTMLContent
						html={introTextHTML}
						componentOverrides={{
							h1: () => (props) => (
								<FancyHeading
									as="h1"
									variant="yellow"
									{...props}
									className="mb-7 md:mb-8 last:mb-0"
								/>
							),
							h2: (Comp) => (props) => <Comp as="p" {...props} />,
							p: (Comp) => (props) => (
								<Comp className="w-full mx-auto max-w-screen-md" {...props} />
							),
						}}
						className="text-center"
					/>
				)}
				{plateLunchImage && (
					<div className="items-center grid gap-4 md:gap-12 md:grid-cols-12">
						<div className="relative w-full mx-auto md:col-span-6 lg:col-span-7 max-w-24rem md:max-w-none">
							<GatsbyImage
								image={plateLunchImage}
								alt={plateLunchImageAlt ?? ''}
							/>
							{[...PRIMARY_DESCRIPTIONS, ...SECONDARY_DESCRIPTIONS].map(
								(description, index) => (
									<DescriptionNumber
										key={description.description}
										index={index}
										filled={true}
										withActiveAnimation={true}
										isActive={activeDescriptionIndex === index}
										activate={() => setActiveDescriptionIndex(index)}
										deactivate={() => setActiveDescriptionIndex(undefined)}
										className="absolute"
										style={{ top: description.top, left: description.left }}
									/>
								),
							)}
						</div>
						<div className="md:col-span-6 lg:col-span-5 grid gap-6 md:gap-9">
							<ul className="grid gap-3 md:gap-5">
								{PRIMARY_DESCRIPTIONS.map((description, index) => (
									<li key={description.description}>
										<Description
											index={index}
											description={description.description}
											isActive={activeDescriptionIndex === index}
											activate={() => setActiveDescriptionIndex(index)}
											deactivate={() => setActiveDescriptionIndex(undefined)}
										/>
									</li>
								))}
							</ul>
							<div className="grid gap-5 md:gap-6">
								<Text
									as="p"
									variant="sans-18"
									className="font-bold text-yellow-50"
								>
									Plus your choice of:
								</Text>
								<ul className="grid gap-3 md:gap-5">
									{SECONDARY_DESCRIPTIONS.map((description, index) => (
										<li key={description.description}>
											<Description
												index={index + PRIMARY_DESCRIPTIONS.length}
												description={description.description}
												isActive={
													activeDescriptionIndex ===
													index + PRIMARY_DESCRIPTIONS.length
												}
												activate={() =>
													setActiveDescriptionIndex(
														index + PRIMARY_DESCRIPTIONS.length,
													)
												}
												deactivate={() => setActiveDescriptionIndex(undefined)}
											/>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				)}
				{textHTML && (
					<HTMLContent
						html={textHTML}
						className="w-full mx-auto md:cols-2 md:col-gap-6 max-w-screen-lg"
					/>
				)}
			</div>
		</BoundedBox>
	)
}

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyPlateLunchDiagramFragment,
	typeof mapDataToContext
>) => ({
	introTextHTML: data?.primary?.intro_text?.html,
	textHTML: data?.primary?.text?.html,
	plateLunchImage: getImage(data.primary?.plate_lunch_image),
	plateLunchImageAlt: data.primary?.plate_lunch_image?.alt,
})

export const mapDataToContext = () => ({
	bg: Symbol(),
})

export const fragment = graphql`
	fragment PageBodyPlateLunchDiagram on PrismicPageDataBodyPlateLunchDiagram {
		primary {
			intro_text {
				text
				html
			}
			text {
				text
				html
			}
			plate_lunch_image {
				alt
				gatsbyImageData(
					width: 1000
					layout: FULL_WIDTH
					placeholder: BLURRED
					sizes: "(min-width: 64rem) 39rem, (min-width: 48rem) calc(50vw - 2rem), 24rem"
				)
			}
		}
	}
`

export default PageBodyPlateLunchDiagram
