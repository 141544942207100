import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { StyledHTMLContent } from '../components/StyledHTMLContent'
import { StandardGrid } from '../components/StandardGrid'

import { ReactComponent as AssetTextureDotsSVG } from '../assets/texture-dots.svg'

export type PageBodyFavoriteDishesProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyFavoriteDishes = ({
	preTextHTML,
	postTextHTML,
	image,
	imageAlt,
	dishes = [],
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodyFavoriteDishesProps) => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		innerMaxWidthClassName="max-w-screen-lg"
		className="relative mx-auto bg-white text-gray-10 max-w-screen-xl"
	>
		{image && (
			<div className="absolute top-0 bottom-0 left-0 items-center hidden w-5/12 py-16 -my-16 overflow-hidden lg:w-4/12 md:flex max-w-30rem">
				<div className="relative w-full transform -translate-x-1/3 xl:-translate-x-20">
					<AssetTextureDotsSVG className="absolute top-0 right-0 block w-2/3 transform rotate-30 text-yellow-50" />
					<GatsbyImage
						image={image}
						alt={imageAlt ?? ''}
						className="block w-full"
					/>
				</div>
			</div>
		)}

		<StandardGrid className="lg:min-h-27rem">
			<div className="content-center grid gap-7 md:gap-9 col-span-full md:col-span-8 md:col-start-5 lg:col-start-4 lg:col-span-9 xl:col-start-3 xl:col-span-11">
				{preTextHTML && (
					<StyledHTMLContent
						html={preTextHTML}
						className="text-center md:text-left"
					/>
				)}
				<ul className="items-baseline grid gap-5 md:grid-cols-2 md:gap-x-8">
					{dishes.length > 0 &&
						dishes.map((dish) => (
							<li key={dish}>
								<StyledHTMLContent
									html={dish}
									className="text-center md:text-left"
								/>
							</li>
						))}
				</ul>
				{postTextHTML && (
					<StyledHTMLContent
						html={postTextHTML}
						className="text-center md:text-left"
					/>
				)}
			</div>
		</StandardGrid>
	</BoundedBox>
)

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyFavoriteDishesFragment,
	typeof mapDataToContext
>) => ({
	preTextHTML: data?.primary?.pre_text?.html,
	postTextHTML: data?.primary?.post_text?.html,
	image: getImage(data?.primary?.image),
	imageAlt: data?.primary?.image?.alt,
	dishes: data?.items
		?.map((item) => item?.dish?.text && item?.dish.html)
		.filter(Boolean),
})

export const mapDataToContext = () => ({
	bg: 'bg-white',
})

export const fragment = graphql`
	fragment PageBodyFavoriteDishes on PrismicPageDataBodyFavoriteDishes {
		primary {
			pre_text {
				text
				html
			}
			post_text {
				text
				html
			}
			image {
				alt
				gatsbyImageData(
					width: 500
					placeholder: BLURRED
					layout: FULL_WIDTH
					sizes: "480px"
				)
			}
		}
		items {
			dish {
				text
				html
			}
		}
	}
`

export default PageBodyFavoriteDishes
