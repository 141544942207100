import { DEFAULT_ONLINE_ORDERING_URL } from '../constants'
import type { UserLocation } from '../hooks/useUserLocation'

type Args = {
	fallback?: string | null
	userLoc: UserLocation
}

export function getOrderNowHref({
	fallback = DEFAULT_ONLINE_ORDERING_URL,
	userLoc,
}: Args) {
	if (userLoc.state !== 'resolved' || !userLoc.nearestLocation.loyaltyPlantUrl)
		return fallback as string

	return userLoc.nearestLocation.loyaltyPlantUrl
}
