import * as React from 'react'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import clsx from 'clsx'
import { Text } from './Text'

const defaultElement = 'dl'

type DayProps = {
  day: string
  hours?: string
}

const Day = ({ day, hours }: DayProps) => (
  <div className="items-center justify-start grid gap-2 grid-flow-col">
    <dd>
      <span className="sr-only">{day}</span>
      <div
        aria-hidden="true"
        className={clsx(
          'flex items-center justify-center w-6 h-6 rounded-full',
          hours ? 'bg-black ' : 'bg-gray-60',
        )}
      >
        <Text variant="sans-13" className="font-bold text-white uppercase">
          {day[0]}
        </Text>
      </div>
    </dd>
    <Text
      as="dt"
      variant="sans-18"
      className={clsx(hours && 'lowercase', !hours && 'text-gray-60')}
    >
      {hours ? hours : 'Closed'}
    </Text>
  </div>
)

type HoursListOwnProps = {
  monday?: string
  tuesday?: string
  wednesday?: string
  thursday?: string
  friday?: string
  saturday?: string
  sunday?: string
}

export type HoursListProps<
  T extends React.ElementType = typeof defaultElement
> = PolymorphicPropsWithoutRef<HoursListOwnProps, T>

export const HoursList = <T extends React.ElementType = typeof defaultElement>({
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
  as,
  className,
  ...restProps
}: HoursListProps<T>) => {
  const Element: React.ElementType = as ?? defaultElement

  return (
    <Element
      className={clsx('justify-start grid gap-2 text-gray-10', className)}
      {...restProps}
    >
      <Day day="Monday" hours={monday} />
      <Day day="Tuesday" hours={tuesday} />
      <Day day="Wednesday" hours={wednesday} />
      <Day day="Thursday" hours={thursday} />
      <Day day="Friday" hours={friday} />
      <Day day="Saturday" hours={saturday} />
      <Day day="Sunday" hours={sunday} />
    </Element>
  )
}
