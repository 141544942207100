import * as React from 'react'
import { point, Feature, Point } from '@turf/helpers'
import { useStaticQuery, graphql } from 'gatsby'

import { toTimeRange } from '../lib/toTimeRange'

export interface LocationFeatureProperties {
	uid: string
	href: string

	title: string
	type?: string | null

	websiteHref?: string | null
	menuHref?: string | null
	phoneNumber?: string | null

	streetAddress?: string | null
	city?: string | null
	state?: string | null
	zipCode?: string | null
	country?: string | null

	mondayHours?: string | null
	tuesdayHours?: string | null
	wednesdayHours?: string | null
	thursdayHours?: string | null
	fridayHours?: string | null
	saturdayHours?: string | null
	sundayHours?: string | null

	ubereatsHref?: string | null
	doordashHref?: string | null
	postmatesHref?: string | null
	biteSquadHref?: string | null
	grubhubHref?: string | null
	loyaltyplantHref?: string | null

	providesOnlineOrdering: boolean
	providesDelivery: boolean
	acceptsGiftCards: boolean

	// Derived data
	isOnApp: boolean
}

export type LocationFeature = Feature<Point, LocationFeatureProperties>

export const useLocationsFeatures = (): LocationFeature[] => {
	const queryData = useStaticQuery<Queries.UseLocationsQuery>(graphql`
		query UseLocations {
			allPrismicLocation {
				nodes {
					uid
					url
					data {
						title {
							text
						}
						type
						menu {
							url
						}
						website_url {
							url
						}
						phone_number {
							text
						}
						street_address {
							text
						}
						city {
							text
						}
						state {
							text
						}
						zip_code {
							text
						}
						country {
							text
						}
						coordinates {
							longitude
							latitude
						}

						###
						# Hours
						###
						monday_hours {
							text
						}
						monday_opening_time
						monday_closing_time
						tuesday_hours {
							text
						}
						tuesday_opening_time
						tuesday_closing_time
						wednesday_hours {
							text
						}
						wednesday_opening_time
						wednesday_closing_time
						thursday_hours {
							text
						}
						thursday_opening_time
						thursday_closing_time
						friday_hours {
							text
						}
						friday_opening_time
						friday_closing_time
						saturday_hours {
							text
						}
						saturday_opening_time
						saturday_closing_time
						sunday_hours {
							text
						}
						sunday_opening_time
						sunday_closing_time

						###
						# Online Ordering
						###
						chownow_url {
							url
						}
						ubereats_url {
							url
						}
						doordash_url {
							url
						}
						postmates_url {
							url
						}
						bite_squad_url {
							url
						}
						grubhub_url {
							url
						}
						loyaltyplant_url {
							url
						}

						###
						# Features
						###
						accepts_gift_cards
						provides_delivery
					}
				}
			}
		}
	`)

	const locations = queryData.allPrismicLocation.nodes

	return React.useMemo(() => {
		const items = []

		for (const location of locations) {
			if (
				location.uid &&
				location.url &&
				location.data?.title?.text &&
				location.data?.coordinates?.longitude &&
				location.data?.coordinates?.latitude
			) {
				items.push(
					point(
						[
							location.data?.coordinates?.longitude,
							location.data?.coordinates?.latitude,
						],
						{
							uid: location.uid,
							href: location.url,

							type: location.data.type,
							title: location.data?.title?.text,
							websiteHref: location.data.website_url?.url,
							menuHref: location.data.menu?.url,
							phoneNumber: location.data.phone_number?.text,

							streetAddress: location.data.street_address?.text,
							city: location.data.city?.text,
							state: location.data.state?.text,
							zipCode: location.data.zip_code?.text,
							country: location.data.country?.text,

							mondayHours:
								location.data.monday_hours?.text ??
								toTimeRange(
									location.data.monday_opening_time,
									location.data.monday_closing_time,
								),
							tuesdayHours:
								location.data.tuesday_hours?.text ??
								toTimeRange(
									location.data.tuesday_opening_time,
									location.data.tuesday_closing_time,
								),
							wednesdayHours:
								location.data.wednesday_hours?.text ??
								toTimeRange(
									location.data.wednesday_opening_time,
									location.data.wednesday_closing_time,
								),
							thursdayHours:
								location.data.thursday_hours?.text ??
								toTimeRange(
									location.data.thursday_opening_time,
									location.data.thursday_closing_time,
								),
							fridayHours:
								location.data.friday_hours?.text ??
								toTimeRange(
									location.data.friday_opening_time,
									location.data.friday_closing_time,
								),
							saturdayHours:
								location.data.saturday_hours?.text ??
								toTimeRange(
									location.data.saturday_opening_time,
									location.data.saturday_closing_time,
								),
							sundayHours:
								location.data.sunday_hours?.text ??
								toTimeRange(
									location.data.sunday_opening_time,
									location.data.sunday_closing_time,
								),

							ubereatsHref: location.data.ubereats_url?.url,
							doordashHref: location.data.doordash_url?.url,
							postmatesHref: location.data.postmates_url?.url,
							biteSquadHref: location.data.bite_squad_url?.url,
							grubhubHref: location.data.grubhub_url?.url,
							loyaltyplantHref: location.data.loyaltyplant_url?.url,

							providesOnlineOrdering: Boolean(
								location.data.ubereats_url?.url ||
									location.data.doordash_url?.url ||
									location.data.postmates_url?.url ||
									location.data.bite_squad_url?.url ||
									location.data.grubhub_url?.url ||
									location.data.loyaltyplant_url?.url,
							),
							acceptsGiftCards: Boolean(location.data.accepts_gift_cards),
							providesDelivery: Boolean(
								location.data.provides_delivery ||
									location.data.ubereats_url?.url ||
									location.data.doordash_url?.url ||
									location.data.postmates_url?.url ||
									location.data.bite_squad_url?.url ||
									location.data.grubhub_url?.url,
							),

							// Derived data
							isOnApp: Boolean(location.data.loyaltyplant_url?.url),
						},
					),
				)
			}
		}

		return items
	}, [locations])
}
