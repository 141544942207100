import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

export const useNavigation = () => {
	const staticData = useStaticQuery<Queries.UseNavigationQuery>(graphql`
		query UseNavigation {
			primary: prismicNavigation(uid: { eq: "primary" }) {
				...UseNavigationData
			}
			secondary: prismicNavigation(uid: { eq: "secondary" }) {
				...UseNavigationData
			}
			footer: prismicNavigation(uid: { eq: "footer" }) {
				...UseNavigationData
			}
			mobileHeader: prismicNavigation(uid: { eq: "mobile-header" }) {
				...UseNavigationData
			}
		}

		fragment UseNavigationData on PrismicNavigation {
			_previewable
			data {
				main {
					... on PrismicNavigationDataMainNavItem {
						id
						primary {
							name
							link {
								uid
								url
								target
							}
						}
						items {
							name
							link {
								uid
								url
								target
							}
						}
					}
				}
			}
		}
	`)
	const data = useMergePrismicPreviewData(staticData)

	return {
		primary: data?.primary?.data?.main ?? [],
		secondary: data?.secondary?.data?.main ?? [],
		footer: data?.footer?.data?.main ?? [],
		mobileHeader: data?.mobileHeader?.data?.main ?? [],
	}
}
