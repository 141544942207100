import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { StandardGrid } from '../components/StandardGrid'
import { FancyHeading } from '../components/FancyHeading'
import { Text } from '../components/Text'
import { VisuallyHidden } from '@reach/visually-hidden'

export type PageBodyHeroImageProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyHeroImage = ({
	image,
	imageAlt,
	textHTML,
	textPlain,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodyHeroImageProps): JSX.Element => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		className="mx-auto text-gray-10 max-w-screen-xl bg-yellow-50"
	>
		<StandardGrid>
			<div className="-mx-6 -mt-10 overflow-hidden col-span-full aspect-w-4 aspect-h-3 md:col-span-4 lg:col-span-5 xl:col-span-6 md:-ml-8 md:-my-13 md:-mr-4 lg:-ml-10 lg:-my-16 lg:-mr-5 bg-yellow-40">
				{image && (
					<div>
						<GatsbyImage
							image={image}
							alt={imageAlt ?? ''}
							className="h-full"
						/>
					</div>
				)}
			</div>

			<div className="content-center pt-10 col-span-full grid gap-8 md:gap-10 lg:gap-12 md:pt-0 md:col-span-8 lg:col-span-7 xl:col-span-6 md:pl-4 lg:pl-8 xl:pl-20 xl:pr-14">
				<VisuallyHidden>
					<h1>{textPlain}</h1>
				</VisuallyHidden>
				{textHTML && (
					<HTMLContent
						html={textHTML}
						className="text-center"
						componentOverrides={{
							h1: () => (props) =>
								(
									<FancyHeading
										as="p"
										variant="redWhite"
										className="mb-7 md:mb-8 last:mb-0"
										{...props}
									/>
								),
							h4: () => (props) =>
								(
									<div className="flex items-center justify-center mb-7 md:mb-8 last:mb-0">
										<div className="flex-shrink-0 bg-white w-7 h-2px" />
										<Text
											variant="sans-24"
											{...props}
											className="mx-3 font-bold tracking-wide uppercase text-red-50"
										/>
										<div className="flex-shrink-0 bg-white w-7 h-2px" />
									</div>
								),
						}}
					/>
				)}
			</div>
		</StandardGrid>
	</BoundedBox>
)

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyHeroImageFragment,
	typeof mapDataToContext
>) => ({
	image: getImage(data.primary?.image),
	imageAlt: data.primary?.image?.alt,
	textHTML: data?.primary?.text?.html,
	textPlain: data?.primary?.text?.text,
})

export const mapDataToContext = () => ({
	bgTop: Symbol(),
	bgBottom: ['bg-yellow-50', Symbol()],
})

export const fragment = graphql`
	fragment PageBodyHeroImage on PrismicPageDataBodyHeroImage {
		primary {
			image {
				alt
				gatsbyImageData(
					width: 1000
					layout: FULL_WIDTH
					placeholder: BLURRED
					sizes: "(min-width: 120rem) 50vw, (min-width: 64rem) 40vw, (min-width: 48rem) 25vw, 100vw"
				)
			}
			text {
				text
				html
			}
		}
	}
`

export default PageBodyHeroImage
