import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import clsx from 'clsx'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'
import { BoundedBox } from '../components/BoundedBox'
import { ButtonLink } from '../components/ButtonLink'
import { Button } from '../components/Button'
import { HTMLContent } from '../components/HTMLContent'
import { FancyHeading } from '../components/FancyHeading'
import { Text } from '../components/Text'

export type PageBodyPromoBoxesProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyRestaurantLocator = ({
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	textHTML,
}: PageBodyPromoBoxesProps): JSX.Element => {
	const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault()

		const target = e.target as typeof e.target & {
			searchQuery: HTMLInputElement
		}

		navigate(`/locations?search=${target.searchQuery.value}`)
	}

	return (
		<BoundedBox
			as="section"
			id={id}
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			innerMaxWidthClassName="max-w-3xl"
			className="max-w-screen-xl mx-auto bg-yellow-50"
		>
			{textHTML && (
				<HTMLContent
					html={textHTML}
					className="text-center"
					componentOverrides={{
						h1: () => (props) => (
							<FancyHeading
								as="h1"
								variant="redWhite"
								className="mb-7 md:mb-8 last:mb-0"
								{...props}
							/>
						),
						h2: () => (props) => (
							<Text
								variant="sans-24"
								{...props}
								className={clsx('font-bold mb-7 md:mb-8 last:mb-0')}
							/>
						),
						p: () => (props) => (
							<Text
								as="p"
								variant="sans-18"
								{...props}
								className="mb-6 md:mb-6 lg:mb-8 last:mb-0 max-w-lg mx-auto"
							/>
						),
					}}
				/>
			)}

			<div className="grid gap-6 mt-10 md:grid-cols-[1fr,auto,1fr] md:items-center lg:gap-8">
				<ButtonLink href="/locations" variant="redLarge">
					Find Location
				</ButtonLink>

				<Text
					variant="sans-24"
					className="uppercase font-bold justify-self-center"
				>
					OR
				</Text>

				<form className="flex" onSubmit={handleSubmit}>
					<label className="flex-grow">
						<span className="sr-only">Search</span>

						<input
							name="searchQuery"
							placeholder="City, State or Zip Code"
							className="w-full min-w-0 px-4 py-3 font-sans placeholder-gray-60 text-15 md:text-18 text-gray-10"
						/>
					</label>

					<Button
						type="submit"
						minWidthClassName="min-w-0"
						paddingClassName="py-4 px-3 md:px-4"
					>
						Search
					</Button>
				</form>
			</div>
		</BoundedBox>
	)
}

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyCirclePromoFragment,
	typeof mapDataToContext
>) => ({
	textHTML: data.primary?.text?.html,
})

export const mapDataToContext = () => ({
	bg: 'bg-white',
})

export const fragment = graphql`
	fragment PageBodyRestaurantLocator on PrismicPageDataBodyRestaurantLocator {
		primary {
			text {
				text
				html
			}
		}
	}
`

export default PageBodyRestaurantLocator
