import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { StandardGrid } from '../components/StandardGrid'
import { HTMLContent } from '../components/HTMLContent'

export type PageBodyCalloutProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyCallout = ({
	textHTML,
	image,
	imageAlt,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodyCalloutProps) => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		innerMaxWidthClassName="max-w-screen-md"
		ptClassName="pt-9 md:pt-10 lg:pt-11"
		pbClassName="pb-9 md:pb-10 lg:pb-11"
		className="relative mx-auto bg-yellow-50 text-gray-10 max-w-screen-xl"
	>
		<div className="absolute inset-0 overflow-hidden pointer-events-none select-none">
			<StaticImage
				src="../../static/images/texture-dots-surfboards.png"
				alt=""
				className="w-full h-full"
				placeholder="blurred"
				objectFit="cover"
				quality={50}
				sizes="100vw"
				layout="fullWidth"
				formats={['avif', 'webp']}
			/>
		</div>

		<StandardGrid className="relative">
			{image && (
				<div className="-my-9 col-span-4 md:col-span-3 md:-my-10 lg:-my-11">
					<GatsbyImage
						image={image}
						alt={imageAlt ?? ''}
						className="block -mb-6"
					/>
				</div>
			)}

			<div
				className={clsx(
					'self-center',
					image ? 'col-span-8 md:col-span-9' : 'col-span-full',
				)}
			>
				{textHTML && (
					<HTMLContent
						html={textHTML}
						componentOverrides={{
							h1: (Comp) => (props) =>
								<Comp as="p" variant="sans-24-30" {...props} />,
						}}
						className={clsx(!image && 'text-center')}
					/>
				)}
			</div>
		</StandardGrid>
	</BoundedBox>
)

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyCalloutFragment,
	typeof mapDataToContext
>) => ({
	textHTML: data?.primary?.text?.html,
	image: getImage(data?.primary?.image),
	imageAlt: data?.primary?.image?.alt,
})

export const mapDataToContext = () => ({
	bg: Symbol(),
})

export const fragment = graphql`
	fragment PageBodyCallout on PrismicPageDataBodyCallout {
		primary {
			text {
				text
				html
			}
			image {
				alt
				gatsbyImageData(width: 400)
			}
		}
	}
`

export default PageBodyCallout
