import { graphql } from 'gatsby'
import { BoundedBox } from '../components/BoundedBox'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { FancyHeading } from '../components/FancyHeading'
import { HTMLContent } from '../components/HTMLContent'
import { ButtonLink } from '../components/ButtonLink'
import { Text } from '../components/Text'

export type PageBodyImageWithPromoProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyImageWithPromo = ({
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	heading,
	image,
	imageAlt,
	promoTitle,
	promoDescription,
	buttonLink,
	buttonText = 'Learn More',
}: PageBodyImageWithPromoProps) => {
	return (
		<BoundedBox
			as="section"
			id={id}
			variant="wide"
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			className="bg-yellow-50"
			innerMaxWidthClassName="max-w-70rem"
		>
			{heading && (
				<FancyHeading as="h2" variant="redWhite" className="text-center">
					{heading}
				</FancyHeading>
			)}

			<div className="aspect-[1146/653] mt-14 shadow-2xl">
				{image && (
					<GatsbyImage
						image={image}
						alt={imageAlt ?? ''}
						className="w-full h-full"
						objectFit="cover"
					/>
				)}
			</div>

			<div className="grid md:grid-cols-2 gap-16 mt-16">
				{promoTitle && (
					<Text
						as="h3"
						className="text-red-50 font-extrabold text-left max-w-sm"
						variant="sans-40"
					>
						{promoTitle}
					</Text>
				)}

				<div>
					{promoDescription && (
						<HTMLContent
							html={promoDescription}
							componentOverrides={{
								h6: (Comp) => (props) =>
									(
										<Comp
											as="h4"
											className="text-red-50 font-extrabold"
											variant="sans-24"
											{...props}
										/>
									),
								p: (_Comp) => (props) =>
									(
										<p
											className="sans-18-1_4 md:sans-21-1_4 lg:sans-24-1_4"
											{...props}
										/>
									),
							}}
						/>
					)}

					{buttonLink && (
						<ButtonLink variant="redLarge" href={buttonLink} className="mt-8">
							{buttonText}
						</ButtonLink>
					)}
				</div>
			</div>
		</BoundedBox>
	)
}

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyImageWithPromoFragment,
	typeof mapDataToContext
>) => ({
	heading: data?.primary?.heading?.text,
	image: getImage(data.primary?.image),
	imageAlt: data.primary?.image?.alt,
	promoTitle: data?.primary?.promo_title?.text,
	promoDescription: data?.primary?.promo_description?.html,
	buttonText: data?.primary?.button_text?.text,
	buttonLink: data?.primary?.button_link?.url || undefined,
})

export const mapDataToContext = () => {
	return {
		bg: 'text-white',
	}
}

export const fragment = graphql`
	fragment PageBodyImageWithPromo on PrismicPageDataBodyImageWithPromo {
		primary {
			heading {
				text
			}
			image {
				alt
				gatsbyImageData(
					width: 1000
					layout: FULL_WIDTH
					placeholder: BLURRED
					sizes: "calc(100vw - 4rem)"
				)
			}
			promo_title {
				text
			}
			promo_description {
				html
			}
			button_text {
				text
			}
			button_link {
				url
			}
		}
	}
`
export default PageBodyImageWithPromo
