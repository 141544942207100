import * as React from 'react'

import PageBodyHeroMenuItem, {
	PageBodyHeroMenuItemProps,
} from '../slices/PageBodyHeroMenuItem'
import PageBodyNutritionFacts, {
	PageBodyNutritionFactsProps,
} from '../slices/PageBodyNutritionFacts'
import PageBodyText from '../slices/PageBodyText'
import { type PageBodyTextProps } from '../slices/PageBodyText'
import { PageTemplateEnhancerProps } from '../templates/page'

export type MenuItemDialogProps = {
	hero: Omit<PageBodyHeroMenuItemProps, keyof PageTemplateEnhancerProps>
	text: Omit<PageBodyTextProps, keyof PageTemplateEnhancerProps>
	facts: Omit<PageBodyNutritionFactsProps, keyof PageTemplateEnhancerProps>
}

export const MenuItemDialog = ({ hero, facts, text }: MenuItemDialogProps) => {
	return (
		<div>
			<PageBodyHeroMenuItem
				{...hero}
				nextIsFooter={false}
				nextSharesBg={false}
				nextOverhangs={false}
				previousIsHeader={false}
				previousSharesBg={false}
				previousOverhangs={false}
			/>

			<PageBodyText
				{...text}
				nextIsFooter={false}
				nextSharesBg
				nextOverhangs={false}
				previousIsHeader={false}
				previousSharesBg={false}
				previousOverhangs={false}
			/>

			<PageBodyNutritionFacts
				{...facts}
				nextIsFooter={false}
				nextSharesBg={false}
				nextOverhangs={false}
				previousIsHeader={false}
				previousSharesBg={false}
				previousOverhangs={false}
			/>
		</div>
	)
}
