exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-prismic-location-url-tsx": () => import("./../../../src/pages/{PrismicLocation.url}.tsx" /* webpackChunkName: "component---src-pages-prismic-location-url-tsx" */),
  "component---src-pages-prismic-menu-item-url-tsx": () => import("./../../../src/pages/{PrismicMenuItem.url}.tsx" /* webpackChunkName: "component---src-pages-prismic-menu-item-url-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

