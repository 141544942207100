import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

export type PageBodyImageProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyFullWidthImage = ({
	image,
	imageAlt,
	id,
}: PageBodyImageProps) => {
	return (
		<section id={id} className="bg-white text-gray-10">
			{image && (
				<GatsbyImage image={image} alt={imageAlt ?? ''} className="w-full" />
			)}
		</section>
	)
}

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyImageFragment,
	typeof mapDataToContext
>) => ({
	image: getImage(data.primary?.image),
	imageAlt: data.primary?.image?.alt,
})

export const mapDataToContext = () => ({
	bg: 'bg-white',
})

export const fragment = graphql`
	fragment PageBodyFullWidthImage on PrismicPageDataBodyFullWidthImage {
		primary {
			image {
				alt
				gatsbyImageData(
					width: 1000
					placeholder: BLURRED
					sizes: "100vw"
					layout: FULL_WIDTH
				)
			}
		}
	}
`

export default PageBodyFullWidthImage
