import * as React from 'react'
import useSWR from 'swr'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import ConditionalWrap from 'conditional-wrap'

import { BoundedBox } from '../components/BoundedBox'
import { useSearchIndex } from '../hooks/useSearchIndex'
import { type PageTemplateEnhancerProps } from '../templates/page'
import {
	SearchResult,
	type Body as SearchBody,
	type Response as SearchResponse,
} from '../api/search'
import { Text } from '../components/Text'
import { useDebounce } from '../hooks/useDebounce'
import clsx from 'clsx'
import { groupBy, pipe, toPairs } from 'remeda'
import { Link } from '../components/Link'
import { Button } from '../components/Button'
import { Spinner } from '../components/Spinner'

type FetcherArgs = [endpoint: string, searchIndexURL: string, query: string]

async function fetcher([endpoint, searchIndexURL, query]: FetcherArgs) {
	const body: SearchBody = { query, searchIndexURL }

	const res = await fetch(endpoint, {
		method: 'POST',
		headers: { 'content-type': 'application/json' },
		body: JSON.stringify(body),
	})
	const data = await res.json()

	return data as SearchResponse
}

const typeDisplayNames: Record<SearchResult['type'], string> = {
	location: 'Locations',
	menu_item: 'Menu Items',
	page: 'Pages',
}

export type PageBodySearchProps = PageTemplateEnhancerProps

const PageBodySearch = ({
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodySearchProps) => {
	const indexData = useSearchIndex()
	const [query, setQuery] = React.useState('')
	const [isInitialSearch, setIsInitialSearch] = React.useState(true)
	const searchQ = useSWR(['/api/search', indexData.indexURL, query], fetcher, {
		keepPreviousData: true,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
		revalidateOnMount: false,
		onSuccess: () => {
			if (isInitialSearch) setIsInitialSearch(false)
		},
	})

	const results = searchQ.data?.results ?? []
	const count = searchQ.data?.count ?? 0

	const resultsByType = pipe(
		results,
		groupBy((res) => res.type),
		toPairs,
	)

	const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault()

		setQuery(e.currentTarget.search.value)
	}

	return (
		<BoundedBox
			as="section"
			id={id}
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			innerMaxWidthClassName="max-w-90rem"
			className="bg-white text-gray-10 max-w-screen-xl mx-auto min-h-40rem"
		>
			<form className="flex items-end" onSubmit={handleSubmit}>
				<label className="space-y-6 block max-w-xs w-full">
					<Text
						variant="sans-14"
						className="font-extrabold tracking-wide uppercase text-red-50"
					>
						Enter a Search Term
					</Text>

					<div
						className="
                            flex border-2 px-2 border-gray-80 transition group border-r-0
                            focus-within:border-yellow-50
                            h-[45px]
                        "
					>
						<MagnifyingGlassIcon className="w-6" />

						<input
							type="search"
							name="search"
							defaultValue={query}
							className="
                                border-0 w-full py-0 pr-0 pl-2
                                focus:outline-none focus:ring-0 
                            "
						/>
					</div>
				</label>

				<Button className="h-[45px]">
					{searchQ.isLoading ? <Spinner className="w-6" /> : 'Search'}
				</Button>
			</form>

			<div className="mt-8">
				<div
					className={clsx(
						searchQ.isLoading && 'grayscale',
						'transition space-y-16',
					)}
				>
					{count > 0 && (
						<Text
							variant="sans-14"
							className="font-extrabold tracking-wide uppercase text-gray-60"
						>
							Found {count} {count <= 1 ? 'result' : 'results'} for "{query}".
						</Text>
					)}

					<div className="space-y-20">
						{count > 0 ? (
							resultsByType.map(([type, items]) => {
								const name = typeDisplayNames[type as SearchResult['type']]

								return (
									<div key={type} className="space-y-8">
										<Text
											as="h2"
											variant="sans-24"
											className="text-red-50 font-extrabold"
										>
											{name}
										</Text>

										<ul
											className="grid gap-8 md:gap-16 md:grid-cols-2 lg:grid-cols-3"
											aria-label={`${name} search results.`}
										>
											{items.map((item) => (
												<li key={item.id}>
													<ConditionalWrap
														condition={Boolean(item.href)}
														wrap={(children) => (
															<Link href={item.href!}>{children}</Link>
														)}
													>
														<>
															<Text
																as="h3"
																variant="sans-18"
																className="text-teal-20 font-bold"
															>
																{item.title}
															</Text>

															{item.description && (
																<Text
																	as="p"
																	variant="sans-16"
																	className="text-grayWarm-20 mt-4"
																>
																	{item.description}
																</Text>
															)}
														</>
													</ConditionalWrap>
												</li>
											))}
										</ul>
									</div>
								)
							})
						) : isInitialSearch ? null : (
							<Text as="p" variant="sans-18">
								No results found. Please try another search.
							</Text>
						)}
					</div>
				</div>
			</div>
		</BoundedBox>
	)
}

export const mapDataToContext = () => ({
	bg: 'bg-white',
})

export default PageBodySearch
