import * as React from 'react'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import clsx from 'clsx'

import { castArray } from '../lib/castArray'
import { firstLeft } from '../lib/firstLeft'

const defaultElement = 'div'

const overhangClassNames = {
	top: ['pt-5', 'md:pt-9', 'lg:pt-12'],
	bottom: ['pb-5', 'md:pb-9', 'lg:pb-12'],
}

const variants = {
	base: {
		ptClassName: 'pt-10 md:pt-13 lg:pt-16',
		pbClassName: 'pb-10 md:pb-13 lg:pb-16',
		plClassName: 'pl-6 md:pl-8 lg:pl-10',
		prClassName: 'pr-6 md:pr-8 lg:pr-10',
	},
	wide: {
		ptClassName: 'pt-10 md:pt-13 lg:pt-16 lg+:pt-20',
		pbClassName: 'pb-10 md:pb-13 lg:pb-16 lg+:pb-20',
		plClassName: 'pl-6 md:pl-8 lg:pl-10',
		prClassName: 'pr-6 md:pr-8 lg:pr-10',
	},
	fullBleed: {
		ptClassName: 'pt-10 md:pt-13 lg:pt-16 lg+:pt-20',
		pbClassName: 'pb-10 md:pb-13 lg:pb-16 lg+:pb-20',
		plClassName: 'pl-0 md:pl-0 lg:pl-0',
		prClassName: 'pr-0 md:pr-0 lg:pr-0',
	},
} as const

type BoundedBoxOwnProps = {
	variant?: keyof typeof variants
	innerMaxWidthClassName?: string
	ptClassName?: string
	pbClassName?: string
	plClassName?: string
	prClassName?: string
	previousOverhangs?: boolean | boolean[]
	nextOverhangs?: boolean | boolean[]
	nextSharesBg?: boolean | boolean[]
}

export type BoundedBoxProps<
	T extends React.ElementType = typeof defaultElement,
> = PolymorphicPropsWithoutRef<BoundedBoxOwnProps, T>

export const BoundedBox = <
	T extends React.ElementType = typeof defaultElement,
>({
	as,
	variant: variantName = 'base',
	innerMaxWidthClassName,
	ptClassName,
	pbClassName,
	plClassName,
	prClassName,
	previousOverhangs: rawPreviousOverhangs,
	nextOverhangs: rawNextOverhangs,
	nextSharesBg: rawNextSharesBg,
	children,
	className,
	...restProps
}: BoundedBoxProps<T>) => {
	const Element: React.ElementType = as ?? defaultElement
	const variant = variants[variantName]

	const previousOverhangs = castArray(rawPreviousOverhangs)
	const nextOverhangs = castArray(rawNextOverhangs)
	const nextSharesBg = castArray(rawNextSharesBg)

	const pbClassNames = (pbClassName ?? variant.pbClassName).split(' ')

	return (
		<Element
			{...restProps}
			className={clsx(
				prClassName ?? variant.prClassName,
				plClassName ?? variant.plClassName,
				ptClassName ?? variant.ptClassName,
				nextSharesBg[0] && !nextOverhangs[0] ? 'pb-0' : pbClassNames[0],
				firstLeft(nextSharesBg, 1) && !firstLeft(nextOverhangs, 1)
					? 'md:pb-0'
					: pbClassNames[1],
				firstLeft(nextSharesBg, 2) && !firstLeft(nextOverhangs, 2)
					? 'lg:pb-0'
					: pbClassNames[2],
				firstLeft(nextSharesBg, 3) && !firstLeft(nextOverhangs, 3)
					? 'xl:pb-0'
					: pbClassNames[3],
				className,
			)}
		>
			<div
				className={clsx(
					'w-full mx-auto h-full',
					previousOverhangs[0] ? overhangClassNames.top[0] : 'pt-0',
					firstLeft(previousOverhangs, 1)
						? overhangClassNames.top[1]
						: 'md:pt-0',
					firstLeft(previousOverhangs, 2)
						? overhangClassNames.top[2]
						: 'lg:pt-0',
					firstLeft(previousOverhangs, 3)
						? overhangClassNames.top[3]
						: 'xl:pt-0',
					nextOverhangs[0] ? overhangClassNames.bottom[0] : 'pb-0',
					firstLeft(nextOverhangs, 1)
						? overhangClassNames.bottom[1]
						: 'md:pb-0',
					firstLeft(nextOverhangs, 2)
						? overhangClassNames.bottom[2]
						: 'lg:pb-0',
					firstLeft(nextOverhangs, 3)
						? overhangClassNames.bottom[3]
						: 'xl:pb-0',
					innerMaxWidthClassName,
				)}
			>
				{children}
			</div>
		</Element>
	)
}
