import * as React from 'react'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import clsx from 'clsx'

const defaultElement = 'div'

const variants = {
	'sans-10': {
		className: 'sans-10-1_35',
	},
	'sans-13': {
		className: 'sans-13-1_25',
	},
	'sans-14': {
		className: 'sans-14-1_25',
	},
	'sans-15': {
		className: 'sans-15-1_35',
	},
	'sans-15-13': {
		className: 'sans-15-1_35 md:sans-13-1_35',
	},
	'sans-16': {
		className: 'sans-16-1_35',
	},
	'sans-17': {
		className: 'sans-17-1_35',
	},
	'sans-18': {
		className: 'sans-18-1_4',
	},
	'sans-18-24': {
		className: 'sans-18-1_1 md:sans-21-1_1 lg:sans-24-1_1',
	},
	'sans-22': {
		className: 'sans-22-1_25',
	},
	'sans-24': {
		className: 'sans-24-1_1',
	},
	'sans-24-30': {
		className: 'sans-24-1_1 md:sans-30-1_1',
	},
	'sans-24-35': {
		className: 'sans-24-1_1 md:sans-30-1_1 lg:sans-35-1_1',
	},
	'sans-32': {
		className: 'sans-32-1_1',
	},
	'sans-35-40': {
		className: 'sans-35-1 md:sans-40-1',
	},
	'sans-40': {
		className: 'sans-40-1',
	},
	'sans-40-64': {
		className: 'sans-40-1 md:sans-48-1 lg+:sans-64-1',
	},
	'sans-48-64': {
		className: 'sans-48-1 md:sans-64-1',
	},
	'sans-48-80': {
		className: 'sans-48-1 md:sans-64-1 lg:sans-80-1',
	},
	'sans-64': {
		className: 'sans-64-1',
	},
	'sans-64-110': {
		className: 'sans-64-1 md:sans-80-1 lg:sans-95-1 xl:sans-110-1',
	},
} as const

type TextOwnProps = {
	variant: keyof typeof variants
	children?: React.ReactNode
}

export type TextProps<T extends React.ElementType = typeof defaultElement> =
	PolymorphicPropsWithoutRef<TextOwnProps, T>

export const Text = <T extends React.ElementType = typeof defaultElement>({
	as,
	variant: variantName,
	className,
	...restProps
}: TextProps<T>) => {
	const Element: React.ElementType = as ?? defaultElement
	const variant = variants[variantName]

	return (
		<Element {...restProps} className={clsx(variant.className, className)} />
	)
}

Text.variants = Object.keys(variants)
