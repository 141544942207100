import * as React from 'react'
import { SkipNavContent } from '@reach/skip-nav'
import { VisuallyHidden } from '@reach/visually-hidden'
import * as Disclosure from '@reach/disclosure'
import { Dialog } from '@reach/dialog'
import { isPathActive } from '@walltowall/helpers'
import ConditionalWrap from 'conditional-wrap'
import FocusTrap from 'focus-trap-react'
import clsx from 'clsx'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { useSiteSettings } from '../hooks/useSiteSettings'
import { useNotificationMessage } from '../hooks/useNotificationMessage'
import { useNavigation } from '../hooks/useNavigation'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { Anchor } from '../components/Anchor'
import { BoundedBox } from '../components/BoundedBox'
import { Button } from '../components/Button'
import { HTMLContent } from '../components/HTMLContent'
import { Icon } from '../components/Icon'
import { Link } from '../components/Link'
import { NearestLocationBox } from '../components/NearestLocationBox'
import { NewsletterDialog } from '../components/NewsletterDialog'
import { Text } from '../components/Text'

import { ReactComponent as AssetLogoSVG } from '../assets/logo.svg'

const navItemTypes = {
	primary: {
		variant: 'sans-14' as const,
		className: 'font-extrabold',
		activeClassName: 'focus:text-yellow-50 lg:focus:text-white',
	},
	secondary: {
		variant: 'sans-13' as const,
		className: 'font-bold',
		activeClassName: '',
	},
}

type NavItemProps = {
	name?: string | null
	href: string
	location?: Location
	children?: React.ReactNode
	type?: keyof typeof navItemTypes
}

const NavItem = ({
	name,
	href,
	location,
	children,
	type = 'primary',
}: NavItemProps) => {
	const hasChildren = React.Children.count(children) > 0
	const isActive = location ? isPathActive(href, location) : false
	const navType = navItemTypes[type]

	return (
		<li className="pt-3 pl-5 lg:pl-8">
			<div className="relative group">
				<Text
					variant={navType.variant}
					className={clsx(navType.className, 'tracking-widest uppercase')}
				>
					<Anchor
						href={href}
						className="p-2 -m-2"
						colorClassName="text-white group"
						focusColorClassName={navType.activeClassName}
						hoverColorClassName={navType.activeClassName}
					>
						{name}

						{type === 'primary' && (
							<div
								className={clsx(
									'absolute left-0 right-0 pointer-events-none -bottom-2 transform translate-y-full h-3px bg-yellow-50 group-hover:opacity-100 group-focus:opacity-100 transition duration-100',
									isActive ? 'opacity-100' : 'opacity-0',
								)}
							/>
						)}
					</Anchor>
				</Text>

				{hasChildren && (
					<ul className="absolute left-0 z-10 bg-white shadow opacity-0 pointer-events-none -bottom-2 min-w-12rem transition duration-100 transform translate-y-full border-t-3 border-b-3 border-yellow-50 focus-within:opacity-100 focus-within:pointer-events-auto group-hover:opacity-100 group-hover:pointer-events-auto">
						{children}
					</ul>
				)}
			</div>
		</li>
	)
}

type NavChildItemProps = {
	name?: string | null
	href: string
}

const NavChildItem = ({ name, href }: NavChildItemProps) => {
	return (
		<li className="p-3">
			<Text variant="sans-15" className="font-bold whitespace-nowrap">
				<Anchor
					href={href}
					colorClassName="text-red-50"
					hoverColorClassName="hover:text-teal-40"
					focusColorClassName="focus:text-teal-40"
				>
					{name}
				</Anchor>
			</Text>
		</li>
	)
}

type MobileNavItemProps = {
	name?: string | null
	href: string
	children?: React.ReactNode
}

const MobileNavItem = ({ name, href, children }: MobileNavItemProps) => {
	const hasChildren = React.Children.count(children) > 0
	const [childrenIsOpen, setChildrenIsOpen] = React.useState(false)

	return (
		<li className="py-3">
			<ConditionalWrap
				condition={hasChildren}
				wrap={(children) => (
					<Disclosure.Disclosure
						open={childrenIsOpen}
						onChange={() => setChildrenIsOpen((state) => !state)}
						id={`mobile-nav-item-${name}`}
					>
						{children}
					</Disclosure.Disclosure>
				)}
			>
				<div>
					<div
						className={clsx(
							'items-center justify-center grid gap-4 grid-flow-col',
							hasChildren && '-mr-8',
						)}
					>
						<Text
							variant="sans-18"
							className="font-extrabold tracking-wider uppercase"
						>
							<Anchor
								href={href}
								colorClassName="text-red-50"
								hoverColorClassName="hover:text-teal-40"
								focusColorClassName="focus:text-teal-40"
								className="p-3 -m-3 outline-none"
							>
								{name}
							</Anchor>
						</Text>
						{hasChildren && (
							<Disclosure.DisclosureButton className="p-3 -m-3 outline-none text-yellow-40 hover:text-teal-40 focus:text-teal-40">
								<VisuallyHidden>Child navigation</VisuallyHidden>
								<Icon
									name="chevronDown"
									className={clsx(
										'w-4 transform transition',
										childrenIsOpen && 'rotate-180',
									)}
								/>
							</Disclosure.DisclosureButton>
						)}
					</div>
					{hasChildren && (
						<Disclosure.DisclosurePanel>
							<ul className="py-2 mt-3 bg-yellow-40">{children}</ul>
						</Disclosure.DisclosurePanel>
					)}
				</div>
			</ConditionalWrap>
		</li>
	)
}

type MobileNavChildItemProps = {
	name?: string | null
	href: string
}

const MobileNavChildItem = ({ name, href }: MobileNavChildItemProps) => {
	return (
		<li className="items-center justify-center py-3 grid gap-5">
			<Text variant="sans-16" className="font-bold">
				<Anchor
					href={href}
					colorClassName="text-white"
					hoverColorClassName="hover:text-red-30"
					focusColorClassName="focus:text-red-30"
					className="outline-none"
				>
					{name}
				</Anchor>
			</Text>
		</li>
	)
}

export type PageBodyHeaderProps = ReturnType<typeof mapDataToProps> & {
	notificationIsActive?: boolean
	notificationTextHTML?: string | null
} & PageTemplateEnhancerProps

const PageBodyHeader = ({
	notificationIsActive = false,
	notificationTextHTML,
	location,
}: PageBodyHeaderProps) => {
	const siteSettings = useSiteSettings()
	const navigations = useNavigation()

	const [mobileNavIsOpen, setMobileNavIsOpen] = React.useState(false)
	const closeMobileNav = () => setMobileNavIsOpen(false)
	const toggleMobileNavIsOpen = () => setMobileNavIsOpen((state) => !state)

	const [newsletterDialogIsOpen, setNewsletterDialogIsOpen] =
		React.useState(false)
	const openNewsletterDialog = () => setNewsletterDialogIsOpen(true)
	const closeNewsletterDialog = () => setNewsletterDialogIsOpen(false)

	const notificationMessage = useNotificationMessage()
	notificationIsActive =
		(notificationIsActive || notificationMessage.isActive) ?? false
	notificationTextHTML = notificationTextHTML ?? notificationMessage.textHTML
	const notificationText = notificationTextHTML ?? notificationMessage.text
	const hasNotificationAndIsActive = notificationIsActive && notificationText

	React.useEffect(() => {
		const htmlEl = document.documentElement

		if (htmlEl) {
			htmlEl.style.overflowY = mobileNavIsOpen ? 'hidden' : 'auto'
		}
	}, [mobileNavIsOpen])

	return (
		<header className="relative z-20">
			<Disclosure.Disclosure
				open={mobileNavIsOpen}
				onChange={toggleMobileNavIsOpen}
				id="mobile-navigation"
			>
				<BoundedBox
					ptClassName="pt-3 lg:pt-5"
					pbClassName="pb-3 lg:pb-5"
					innerMaxWidthClassName="max-w-screen-xl"
					className="relative z-10 text-white bg-cover shadow-md bg-red-30"
					style={{
						backgroundImage: `url('https://images.prismic.io/hawaiianbarbecue/b9c18e41-f9fb-44e2-8cf4-cb4069c4c9fa_texture-header.png?auto=compress,format')`,
					}}
				>
					<div className="grid gap-8 grid-cols-auto-1fr">
						<Link href="/" className="self-end">
							<VisuallyHidden>{siteSettings.siteName}</VisuallyHidden>
							<AssetLogoSVG className="block -mb-6 -ml-2 xl:ml-10 w-17 lg:-mb-10 lg:w-23" />
						</Link>

						<div className="items-center justify-end grid gap-6 grid-flow-col lg:hidden">
							{navigations.mobileHeader.length > 0 && (
								<ul className="flex flex-wrap items-center justify-end -mt-3 -ml-5 lg:-ml-8">
									{navigations.mobileHeader.map(
										(item) =>
											item?.primary?.link?.url && (
												<NavItem
													key={item.primary.name}
													name={item?.primary?.name}
													href={item.primary.link.url}
													location={location}
												/>
											),
									)}
								</ul>
							)}

							<Disclosure.DisclosureButton className="px-6 py-3 -mx-6 -my-4 focus:text-yellow-50 hover:text-yellow-50 transition">
								<VisuallyHidden>Navigation</VisuallyHidden>
								<Icon name="menu" className="w-7" />
							</Disclosure.DisclosureButton>
						</div>

						<div className="flex-col justify-center justify-self-end -mt-3 -ml-8 items-end gap-2 hidden lg:flex">
							<ul className="flex flex-wrap items-center">
								{navigations.secondary.map(
									(item) =>
										item?.primary?.link?.url && (
											<NavItem
												key={item.primary.name}
												name={item.primary.name}
												href={item.primary.link.url}
												location={location}
												type="secondary"
											/>
										),
								)}

								<li className="pt-3 pl-5 lg:pl-8 text-white">
									<Link href="/search">
										<VisuallyHidden>Search site</VisuallyHidden>
										<MagnifyingGlassIcon className="w-5" />
									</Link>
								</li>
							</ul>

							<ul className="flex flex-wrap items-center">
								{navigations.primary.map(
									(item) =>
										item?.primary?.link?.url && (
											<NavItem
												key={item.primary.name}
												name={item.primary.name}
												href={item.primary.link.url}
												location={location}
											>
												{item.items
													?.filter((c) => Boolean(c.link?.url))
													.map((c) => (
														<NavChildItem
															key={c.name}
															name={c.name}
															href={c.link!.url!}
														/>
													))}
											</NavItem>
										),
								)}
							</ul>
						</div>
					</div>
				</BoundedBox>

				<BoundedBox
					ptClassName="pt-0"
					pbClassName="pb-0"
					innerMaxWidthClassName="max-w-screen-xl"
				>
					<div className="relative">
						<button
							onClick={openNewsletterDialog}
							className="absolute top-0 right-0 hidden px-5 py-2 text-white shadow bg-teal-40 lg:block hover:bg-teal-20 focus:bg-teal-20 transition lg:-mr-10 xl:mr-0 contrast-more:bg-teal-30"
						>
							<Text
								variant="sans-10"
								className="font-bold tracking-widest text-center uppercase"
							>
								Connect With Us
							</Text>
						</button>
					</div>
				</BoundedBox>

				<div
					className={clsx(
						'fixed z-30 inset-0 bg-black pointer-events-none transition',
						mobileNavIsOpen ? 'bg-opacity-50' : 'bg-opacity-0',
					)}
				/>

				<Disclosure.DisclosurePanel
					className={clsx(
						'fixed inset-0 z-40 transition transform block overflow-auto',
						mobileNavIsOpen
							? 'translate-x-0 visible'
							: 'translate-x-full invisible',
					)}
				>
					<FocusTrap
						active={mobileNavIsOpen}
						focusTrapOptions={{
							onDeactivate: () => closeMobileNav(),
							clickOutsideDeactivates: true,
						}}
					>
						<div className="relative flex flex-col max-w-full min-h-full ml-auto shadow-lg pointer-events-auto w-19rem">
							<ul className="flex flex-col justify-center flex-grow py-3 bg-yellow-50">
								{[...navigations.primary, ...navigations.secondary].map(
									(item) =>
										item?.primary?.link?.url && (
											<MobileNavItem
												key={item.primary.name}
												name={item.primary.name}
												href={item.primary.link.url}
											>
												{item.items?.map(
													(child) =>
														child?.link?.url && (
															<MobileNavChildItem
																key={child.name}
																name={child.name}
																href={child.link.url}
															/>
														),
												)}
											</MobileNavItem>
										),
								)}

								<MobileNavItem name="Search" href="/search" />

								<li className="flex justify-center py-3">
									<Button
										type="submit"
										variant="tealSmall"
										onClick={() => {
											closeMobileNav()
											openNewsletterDialog()
										}}
									>
										Connect With Us
									</Button>
								</li>
							</ul>

							<NearestLocationBox />

							<div className="absolute top-4 -left-3">
								<Disclosure.DisclosureButton className="p-2 text-white bg-teal-40 hover:bg-teal-20 focus:bg-teal-20 transition">
									<VisuallyHidden>Close navigation</VisuallyHidden>
									<Icon name="close" className="w-7" />
								</Disclosure.DisclosureButton>
							</div>
						</div>
					</FocusTrap>
				</Disclosure.DisclosurePanel>
			</Disclosure.Disclosure>

			<Dialog
				aria-label="Connect With Us"
				isOpen={newsletterDialogIsOpen}
				onDismiss={closeNewsletterDialog}
				className="z-30 w-full mx-auto outline-none md:my-12 lg:my-23 max-w-screen-sm"
			>
				<NewsletterDialog closeDialog={closeNewsletterDialog} />
			</Dialog>

			<SkipNavContent />

			{hasNotificationAndIsActive && (
				<BoundedBox
					innerMaxWidthClassName="max-w-screen-lg"
					ptClassName="pt-7 xl:pt-6"
					pbClassName="pb-6"
					className="shadow bg-gray-95 text-gray-10"
				>
					<div className="items-center justify-center -ml-1 grid gap-3 md:gap-4 grid-flow-col">
						<Icon
							name="notification"
							className="self-start w-7 md:w-8 lg:w-10 text-teal-40 -mt-2px"
						/>
						<HTMLContent
							html={notificationTextHTML}
							componentOverrides={{
								p: (Comp) => (props) => <Comp variant="sans-16" {...props} />,
							}}
						/>
					</div>
				</BoundedBox>
			)}
		</header>
	)
}

export const mapDataToProps = ({ meta }: MapDataToPropsArgs) => ({
	location: meta?.location,
})

export const mapDataToContext = () => ({
	bg: Symbol(),
})

export default PageBodyHeader
