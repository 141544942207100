import * as React from 'react'
import { graphql } from 'gatsby'
import clsx from 'clsx'
import { VisuallyHidden } from '@reach/visually-hidden'

import {
	MapDataToContextArgs,
	MapDataToPropsArgs,
} from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { FancyHeading } from '../components/FancyHeading'
import { ButtonLink } from '../components/ButtonLink'

const variants = {
	White: {
		backgroundClassName: 'bg-white',
		colorClassName: 'text-gray-10',
		h1FancyHeadingVariant: 'redYellowSmall',
		h2ColorClassName: 'text-teal-40',
		h3ColorClassName: 'text-red-50',
		buttonVariant: 'redLarge',
	},
	Teal: {
		backgroundClassName: 'bg-teal-40 contrast-more:bg-teal-20',
		colorClassName: 'text-white',
		h1FancyHeadingVariant: 'yellowSmall',
		h2ColorClassName: 'text-yellow-50',
		h3ColorClassName: 'text-white',
		buttonVariant: 'yellowLarge',
	},
} as const

const defaultVariant = 'White'

export type PageBodyCenteredTextProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyCenteredText = ({
	variant: variantName = defaultVariant,
	textHTML,
	buttonText = 'Learn More',
	buttonHref,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodyCenteredTextProps) => {
	const variant = variants[variantName]

	return (
		<BoundedBox
			as="section"
			id={id}
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			innerMaxWidthClassName="max-w-screen-lg"
			className={clsx(
				'mx-auto max-w-screen-xl',
				variant.backgroundClassName,
				variant.colorClassName,
			)}
		>
			<div className="grid gap-8 justify-items-center md:gap-9">
				{textHTML && (
					<HTMLContent
						html={textHTML}
						componentOverrides={{
							h1: () => (props) =>
								(
									<FancyHeading
										as="h2"
										variant={variant.h1FancyHeadingVariant}
										{...props}
										className="max-w-screen-md mx-auto mb-7 md:mb-8 last:mb-0"
									/>
								),
							h2: (Comp) => (props) =>
								(
									<Comp
										as="p"
										className={clsx(
											'max-w-screen-md w-full mx-auto',
											variant.h2ColorClassName,
										)}
										{...props}
									/>
								),
							h3: (Comp) => (props) =>
								<Comp as="p" className={variant.h3ColorClassName} {...props} />,
						}}
						className="text-center"
					/>
				)}
				{buttonHref && (
					<ButtonLink variant={variant.buttonVariant} href={buttonHref}>
						<VisuallyHidden>Learn more by visiting {buttonHref}</VisuallyHidden>
						{buttonText}
					</ButtonLink>
				)}
			</div>
		</BoundedBox>
	)
}

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyCenteredTextFragment,
	typeof mapDataToContext
>) => ({
	variant: data?.primary?.variant as keyof typeof variants | undefined,
	textHTML: data?.primary?.text?.html,
	buttonText: data.primary?.button_text?.text,
	buttonHref: data.primary?.button_link?.url || undefined,
})

export const mapDataToContext = ({
	data,
}: MapDataToContextArgs<Queries.PageBodyCenteredTextFragment>) => {
	const variant =
		variants[(data.primary?.variant as keyof typeof variants) ?? defaultVariant]

	return {
		bg: variant.backgroundClassName,
	}
}

export const fragment = graphql`
	fragment PageBodyCenteredText on PrismicPageDataBodyCenteredText {
		primary {
			variant
			text {
				text
				html
			}
			button_text {
				text
			}
			button_link {
				url
			}
		}
	}
`

export default PageBodyCenteredText
