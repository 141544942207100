import * as React from 'react'
import { graphql } from 'gatsby'
import useEmblaCarousel from 'embla-carousel-react'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { Text } from '../components/Text'

import { Link } from '../components/Link'
import { FancyHeading } from '../components/FancyHeading'

const Swiper = (
	props: Pick<PageBodySwipeableBoxesProps, 'highlights' | 'mainHighlight'>,
) => {
	const [rEmbla, emblaApi] = useEmblaCarousel({
		align: 'start',
		containScroll: 'trimSnaps',
	})
	const [atEnd, setAtEnd] = React.useState(false)
	const [atStart, setAtStart] = React.useState(true)

	const prevSlide = () => emblaApi?.scrollPrev()
	const nextSlide = () => emblaApi?.scrollNext()

	React.useEffect(() => {
		const handleSelect = () => {
			if (!emblaApi) return

			setAtEnd(!emblaApi.canScrollNext())
			setAtStart(!emblaApi.canScrollPrev())
		}

		emblaApi?.on('select', handleSelect)

		return () => {
			emblaApi?.off('select', handleSelect)
		}
	}, [emblaApi])

	return (
		<div className="relative z-10">
			<div
				className="overflow-hidden py-8 md:pt-16 md:pb-12 -mx-6 px-6 md:-mx-8 md:px-8 lg:-mx-10 lg:px-10"
				ref={rEmbla}
			>
				<div className="flex max-w-70rem w-full mx-auto gap-4">
					<div className="text-center flex flex-col items-center justify-center text-white bg-red-50 aspect-[350/297] shrink-0 w-[20rem] md:w-[21.875rem] shadow-swiper px-6 py-7">
						<p className="font-bold sans-24-1_25">{props.mainHighlight}</p>
					</div>

					{props.highlights.map((h) => {
						if (!h.href) return null

						return (
							<Link
								key={h.heading}
								href={h.href}
								className="text-center flex flex-col bg-white aspect-[350/297] shrink-0 w-[20rem] md:w-[21.875rem] shadow-swiper px-6 py-7 select-none"
							>
								<Text
									as="h3"
									variant="sans-18"
									className="font-bold text-red-50"
								>
									{h.subheading}
								</Text>

								<FancyHeading variant="redYellowXSmall" className="mt-6">
									{h.heading}
								</FancyHeading>

								<p className="mt-auto uppercase font-bold sans-14-1 underline text-red-50">
									{h.linkText}
								</p>
							</Link>
						)
					})}
				</div>
			</div>

			<div className="flex items-center gap-3 w-full max-w-70rem mx-auto">
				<button
					onClick={prevSlide}
					className="w-10 h-10 md:w-12 md:h-12 disabled:cursor-not-allowed disabled:grayscale"
					disabled={atStart}
				>
					<span className="sr-only">Previous Highlight</span>
					<img src="/icons/arrow-circle.svg" alt="" loading="lazy" />
				</button>

				<button
					onClick={nextSlide}
					className="w-10 h-10 md:w-12 md:h-12 disabled:cursor-not-allowed disabled:grayscale"
					disabled={atEnd}
				>
					<span className="sr-only">Next Highlight</span>
					<img
						src="/icons/arrow-circle.svg"
						alt=""
						loading="lazy"
						className="rotate-180"
					/>
				</button>
			</div>
		</div>
	)
}

export type PageBodySwipeableBoxesProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodySwipeableBoxes = ({
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	heading,
	subheading,
	highlights,
	mainHighlight,
}: PageBodySwipeableBoxesProps) => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		className="bg-white text-gray-10 max-w-screen-xl mx-auto relative"
	>
		<div className="max-w-70rem w-full mx-auto">
			{subheading && (
				<Text
					as="h3"
					variant="sans-18-24"
					className="font-bold leading-1_25 text-teal-40 contrast-more:text-teal-20"
				>
					{subheading}
				</Text>
			)}

			{heading && (
				<FancyHeading variant="redYellow" className="mt-5 md:mt-7" as="h2">
					{heading}
				</FancyHeading>
			)}
		</div>

		<Swiper highlights={highlights} mainHighlight={mainHighlight} />
	</BoundedBox>
)

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodySwipeableBoxesFragment,
	typeof mapDataToContext
>) => ({
	heading: data.primary?.heading?.text,
	subheading: data.primary?.subheading?.text,
	mainHighlight: data.primary.main_highlight.text,
	highlights: data.items.map((i) => ({
		heading: i.heading.text,
		subheading: i.subheading.text,
		href: i.link?.url,
		linkText: i.link_text || 'Read More',
	})),
})

export const mapDataToContext = () => ({
	bg: Symbol(''),
})

export const fragment = graphql`
	fragment PageBodySwipeableBoxes on PrismicPageDataBodySwipeableBoxes {
		primary {
			subheading {
				text
			}
			heading {
				text
			}
			main_highlight {
				text
			}
		}
		items {
			subheading {
				text
			}
			heading {
				text
			}
			link {
				url
			}
			link_text
		}
	}
`

export default PageBodySwipeableBoxes
