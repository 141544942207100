const datetimeFormatter = new Intl.DateTimeFormat('en-US', {
	hour: 'numeric',
	minute: '2-digit',
	hour12: true,
	timeZone: 'Pacific/Honolulu',
})

export const toTimeRange = (
	datetimeStart: string | undefined | null,
	datetimeEnd: string | undefined | null,
): string | undefined => {
	if (!datetimeStart && !datetimeEnd) {
		return undefined
	}

	const startTime = datetimeStart
		? datetimeFormatter.format(new Date(datetimeStart))
		: undefined
	const endTime = datetimeEnd
		? datetimeFormatter.format(new Date(datetimeEnd))
		: undefined

	return [startTime, endTime].filter(Boolean).join(' - ')
}
