import { castArray } from './castArray'

/**
 * Determines whether the passed value is a function.
 *
 * @param value - The value to check
 *
 * @returns true if value is a function, false otherwise.
 */
const isFunction = (value: unknown): boolean =>
  Boolean(
    value &&
      // eslint-disable-next-line @typescript-eslint/ban-types
      (value as Function).constructor &&
      // eslint-disable-next-line @typescript-eslint/ban-types
      (value as Function).call &&
      // eslint-disable-next-line @typescript-eslint/ban-types
      (value as Function).apply,
  )

/**
 * Creates an array of grouped elements based on the provided callback. The callback is invoked with the appropriate index values of each array for the current iteration.
 *
 * @param callback The function to iterate over each zipped pair.
 * @param arrOne The first array.
 * @param arrTwo The second array.
 *
 * @returns The new array of grouped elements.
 */
const zipWith = <T1, T2, T3>(
  callback: (itemA: T1, itemB: T2) => T3,
  arrOne: T1[],
  arrTwo: T2[],
): T3[] | boolean[] => {
  if (!isFunction(callback)) {
    return []
  }

  const a = castArray(arrOne)
  const b = castArray(arrTwo)

  const length = Math.max(a.length, b.length)
  if (length === 0) {
    return [true]
  }

  const res: T3[] = []

  for (let i = 0; i < length; i++) {
    res[i] = callback(a[i], b[i])
  }

  return res
}

/**
 * Returns an array containing boolean values of whether a given set of arrays have strict equality pairs.
 *
 * @param pairs The first set of pairs.
 * @param pairsTwo The second set of pairs
 *
 * @returns An array containing the boolean values.
 */
export const pairsEq = <T>(pairsA: T[] | T, pairsB: T[] | T): boolean[] =>
  zipWith((itemA: T, itemB: T) => itemA === itemB, pairsA as T[], pairsB as T[])
