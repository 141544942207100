import * as React from 'react'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import clsx from 'clsx'

const defaultElement = 'div'

type StandardGridOwnProps = {
  children?: React.ReactNode
  gapXClassName?: string
  gapYClassName?: string
}

export type StandardGridProps<
  T extends React.ElementType = typeof defaultElement
> = PolymorphicPropsWithoutRef<StandardGridOwnProps, T>

export const StandardGrid = <
  T extends React.ElementType = typeof defaultElement
>({
  as,
  gapXClassName = 'gap-x-5 md:gap-x-8 lg:gap-x-10',
  gapYClassName,
  className,
  ...restProps
}: StandardGridProps<T>) => {
  const Element: React.ElementType = as ?? defaultElement

  return (
    <Element
      className={clsx(
        'grid grid-cols-12',
        gapXClassName,
        gapYClassName,
        className,
      )}
      {...restProps}
    />
  )
}
