import * as React from 'react'
import clsx from 'clsx'

import { Link, LinkProps } from './Link'

export type AnchorProps = LinkProps & {
	colorClassName?: string | null
	focusColorClassName?: string | null
	hoverColorClassName?: string | null
}

export const Anchor = ({
	className,
	colorClassName = 'text-teal-40 contrast-more:text-teal-10 contrast-more:underline',
	focusColorClassName = 'focus:text-red-50',
	hoverColorClassName = 'hover:text-red-50',
	...props
}: AnchorProps): JSX.Element => (
	<Link
		className={clsx(
			'transition',
			colorClassName,
			focusColorClassName,
			hoverColorClassName,
			className,
		)}
		{...props}
	/>
)
