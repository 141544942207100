import type { LinkResolverFunction } from '@prismicio/helpers'
import { linkResolverPathFor } from '@walltowall/helpers'

export const linkResolver: LinkResolverFunction = (doc) => {
	switch (doc.type) {
		case 'menu_item':
			return `/food/${doc.uid}/`

		case 'location':
			return `/locations/${doc.uid}/`

		case 'page':
			return linkResolverPathFor(doc as any)

		default:
			return '/'
	}
}
