import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'

export type PageBodyImageProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyImage = ({
	captionHTML,
	image,
	imageAlt,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodyImageProps) => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		innerMaxWidthClassName="max-w-screen-lg"
		className="mx-auto bg-white text-gray-10 max-w-screen-xl"
	>
		<div className="grid gap-4 md:gap-6 lg:gap-8 justify-items-center">
			{image && (
				<GatsbyImage image={image} alt={imageAlt ?? ''} className="w-full" />
			)}
			{captionHTML && (
				<HTMLContent
					html={captionHTML}
					componentOverrides={{
						p: (Comp) => (props) => <Comp variant="sans-16" {...props} />,
					}}
					className="w-full mx-auto text-center text-gray-60 max-w-screen-md"
				/>
			)}
		</div>
	</BoundedBox>
)

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyImageFragment,
	typeof mapDataToContext
>) => ({
	captionHTML: data.primary?.caption?.html,
	image: getImage(data.primary?.image),
	imageAlt: data.primary?.image?.alt,
})

export const mapDataToContext = () => ({
	bg: 'bg-white',
})

export const fragment = graphql`
	fragment PageBodyImage on PrismicPageDataBodyImage {
		primary {
			caption {
				text
				html
			}
			image {
				alt
				gatsbyImageData(
					width: 1200
					layout: FULL_WIDTH
					placeholder: BLURRED
					sizes: "calc(100vw - 4rem)"
				)
			}
		}
	}
`

export default PageBodyImage
