/**
 * Returns the first non-nullish element of an array starting from the
 * provided index and moving left.
 *
 * @param values Array of elements.
 * @param index Starting index.
 *
 * @returns First non-null element from `values`. If no value can be found,
 * `undefined` is returned.
 */
export const firstLeft = <T>(values: T[], index: number) => {
  for (let i = index; i >= 0; i--) {
    if (values[i] != null) {
      return values[i]
    }
  }
}
