import * as React from 'react'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import { superscriptCharHTML } from '../lib/superscriptChar'

const defaultElement = 'span'

type SuperscriptCharacterOwnProps = {
  children: string
  character: string
}

export type SuperscriptCharacterProps<
  T extends React.ElementType = typeof defaultElement
> = PolymorphicPropsWithoutRef<SuperscriptCharacterOwnProps, T>

export const SuperscriptCharacter = <
  T extends React.ElementType = typeof defaultElement
>({
  children,
  character: char,
  as,
  ...restProps
}: SuperscriptCharacterProps<T>) => {
  const Element: React.ElementType = as ?? defaultElement

  return (
    <Element
      dangerouslySetInnerHTML={{ __html: superscriptCharHTML(children, char) }}
      {...restProps}
    />
  )
}
