import * as React from 'react'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'

const defaultElement = 'span'

type AddressOwnProps = {
	streetAddress?: string | null
	state?: string | null
	city?: string | null
	zipCode?: string | null
	country?: string | null
}

export type AddressProps<T extends React.ElementType = typeof defaultElement> =
	PolymorphicPropsWithoutRef<AddressOwnProps, T>

export const Address = <T extends React.ElementType = typeof defaultElement>({
	streetAddress,
	state,
	city,
	zipCode,
	country,
	as,
	...restProps
}: AddressProps<T>) => {
	const Element: React.ElementType = as ?? defaultElement

	return (
		<Element {...restProps}>
			{streetAddress && (
				<>
					{streetAddress}
					<br />
				</>
			)}
			{(city || state || zipCode) && (
				<>
					{[city, state].filter(Boolean).join(', ')} {zipCode}
				</>
			)}
			{country && (
				<>
					<br />
					{country}
				</>
			)}
		</Element>
	)
}
