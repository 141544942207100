import * as React from 'react'
import clsx from 'clsx'

import { ReactComponent as AssetIconNotification } from '../assets/icon-notification.svg'
import { ReactComponent as AssetIconFacebookCircle } from '../assets/icon-facebook-circle.svg'
import { ReactComponent as AssetIconTwitterCircle } from '../assets/icon-twitter-circle.svg'
import { ReactComponent as AssetIconInstagramCircle } from '../assets/icon-instagram-circle.svg'
import { ReactComponent as AssetIconYouTubeCircle } from '../assets/icon-youtube-circle.svg'
import { ReactComponent as AssetIconMenu } from '../assets/icon-menu.svg'
import { ReactComponent as AssetIconClose } from '../assets/icon-close.svg'
import { ReactComponent as AssetIconPin } from '../assets/icon-pin.svg'
import { ReactComponent as AssetIconChevronDown } from '../assets/icon-chevron-down.svg'
import { ReactComponent as AssetIconWebsite } from '../assets/icon-website.svg'
import { ReactComponent as AssetIconPhone } from '../assets/icon-phone.svg'
import { ReactComponent as AssetIconUbereats } from '../assets/icon-ubereats.svg'
import { ReactComponent as AssetIconDoordash } from '../assets/icon-doordash.svg'
import { ReactComponent as AssetIconPostmates } from '../assets/icon-postmates.svg'
import { ReactComponent as AssetIconBiteSquad } from '../assets/icon-bite-squad.svg'
import { ReactComponent as AssetIconGrubhub } from '../assets/icon-grubhub.svg'
import { ReactComponent as AssetIconDelivery } from '../assets/icon-delivery.svg'
import { ReactComponent as AssetIconGiftCard } from '../assets/icon-gift-card.svg'
import { ReactComponent as AssetIconOnlineOrdering } from '../assets/icon-online-ordering.svg'
import { ReactComponent as AssetIconLocationCardDelivery } from '../assets/icon-location-card-delivery.svg'
import { ReactComponent as AssetIconLocationCardGiftCard } from '../assets/icon-location-card-gift-card.svg'
import { ReactComponent as AssetIconLocationCardOnlineOrdering } from '../assets/icon-location-card-online-ordering.svg'
import { ReactComponent as AssetIconPlay } from '../assets/icon-play.svg'
import { ReactComponent as AssetIconDirections } from '../assets/icon-directions.svg'

const icons = {
  notification: AssetIconNotification,
  facebookCircle: AssetIconFacebookCircle,
  twitterCircle: AssetIconTwitterCircle,
  instagramCircle: AssetIconInstagramCircle,
  youtubeCircle: AssetIconYouTubeCircle,
  menu: AssetIconMenu,
  close: AssetIconClose,
  pin: AssetIconPin,
  chevronDown: AssetIconChevronDown,
  website: AssetIconWebsite,
  phone: AssetIconPhone,
  ubereats: AssetIconUbereats,
  doordash: AssetIconDoordash,
  postmates: AssetIconPostmates,
  biteSquad: AssetIconBiteSquad,
  grubhub: AssetIconGrubhub,
  delivery: AssetIconDelivery,
  giftCard: AssetIconGiftCard,
  onlineOrdering: AssetIconOnlineOrdering,
  locationCardDelivery: AssetIconLocationCardDelivery,
  locationCardGiftCard: AssetIconLocationCardGiftCard,
  locationCardOnlineOrdering: AssetIconLocationCardOnlineOrdering,
  play: AssetIconPlay,
  directions: AssetIconDirections,
} as const

export type IconProps = {
  name: keyof typeof icons
  displayClassName?: string
} & React.SVGProps<SVGSVGElement>

export const Icon = ({
  name,
  displayClassName = 'block',
  className,
  ...restProps
}: IconProps) => {
  const SVG = icons[name]

  return <SVG {...restProps} className={clsx(displayClassName, className)} />
}
