import * as React from 'react'
import { graphql } from 'gatsby'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'

export type PageBodyHtmlProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyHtml = ({
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	code,
}: PageBodyHtmlProps) => {
	if (!code) return null

	return (
		<BoundedBox
			as="section"
			id={id}
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			innerMaxWidthClassName="max-w-screen-lg"
			className="bg-white text-gray-10 max-w-screen-xl mx-auto"
		>
			<div dangerouslySetInnerHTML={{ __html: code }} />
		</BoundedBox>
	)
}

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyHtmlFragment,
	typeof mapDataToContext
>) => ({
	code: data.primary.code.text,
})

export const mapDataToContext = () => ({
	bg: 'bg-white',
})

export const fragment = graphql`
	fragment PageBodyHtml on PrismicPageDataBodyHtml {
		primary {
			code {
				text
			}
		}
	}
`

export default PageBodyHtml
