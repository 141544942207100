import { useStaticQuery, graphql } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

const YEAR = new Date().getFullYear().toString()

export const useSiteSettings = () => {
	const staticData = useStaticQuery<Queries.PrismicSiteSettingsQuery>(graphql`
		query PrismicSiteSettings {
			prismicSettings {
				_previewable
				data {
					site_name {
						text
					}
					site_description {
						text
					}
					site_copyright {
						text
					}
					site_disclaimer {
						text
						html
					}
					facebook_handle
					twitter_handle
					instagram_handle
					youtube_handle
					newsletter_popup_text {
						text
						html
					}
					nutrition_facts_disclaimer {
						text
						html
					}
					location_meta_description {
						text
					}
				}
			}
		}
	`)
	const data = useMergePrismicPreviewData(staticData)

	const settings = data?.prismicSettings?.data

	return {
		siteName: settings?.site_name?.text,
		siteDescription: settings?.site_description?.text,
		siteCopyright: settings?.site_copyright?.text?.replace?.(/\$YEAR/g, YEAR),
		siteDisclaimerHTML: settings?.site_disclaimer?.html,
		facebookHandle: settings?.facebook_handle,
		facebookURL: settings?.facebook_handle
			? `https://facebook.com/${settings?.facebook_handle}`
			: undefined,
		twitterHandle: settings?.twitter_handle,
		twitterURL: settings?.twitter_handle
			? `https://twitter.com/${settings?.twitter_handle}`
			: undefined,
		instagramHandle: settings?.instagram_handle,
		instagramURL: settings?.instagram_handle
			? `https://instagram.com/${settings?.instagram_handle}`
			: undefined,
		youtubeHandle: settings?.youtube_handle,
		youtubeURL: settings?.youtube_handle
			? `https://youtube.com/${settings?.youtube_handle}`
			: undefined,
		newsletterPopupTextHTML: settings?.newsletter_popup_text?.html,
		nutritionFactsDisclaimerText: settings?.nutrition_facts_disclaimer?.text,
		nutritionFactsDisclaimerHTML: settings?.nutrition_facts_disclaimer?.html,
		locationMetaDescription: settings?.location_meta_description?.text,
	}
}
