/**
 * This module is imported in the following files:
 *
 *   - /gatsby-browser.js
 *   - /gatsby-ssr.js
 *   - /.storybook/preview.js
 *
 * All exports are re-exported in the gatsby-* files allowing access to the
 * Gatsby Browser and SSR APIs.
 *
 * @see https://www.gatsbyjs.org/docs/browser-apis/
 * @see https://www.gatsbyjs.org/docs/ssr-apis/
 */

import * as React from 'react'
import { GatsbyBrowser } from 'gatsby'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

import { DebugProvider } from './hooks/useDebug'
import { UserLocationProvider } from './hooks/useUserLocation'
import { linkResolver } from './linkResolver'
import { PageTemplate } from './templates/page'

import './index.css'

export const wrapRootElement: NonNullable<GatsbyBrowser['wrapRootElement']> = ({
	element,
}) => (
	<DebugProvider>
		<PrismicPreviewProvider
			repositoryConfigs={[
				{
					repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME!,
					linkResolver,
					componentResolver: {
						page: PageTemplate,
					},
				},
			]}
		>
			<UserLocationProvider>{element}</UserLocationProvider>
		</PrismicPreviewProvider>
	</DebugProvider>
)
