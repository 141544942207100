/**
 * Root Prismic slices file where Page Body slices are registered for use in the
 * page template file.
 *
 * @see /docs/guide-create-a-slice.md for more details.
 */

import { graphql } from 'gatsby'

import { reshapeSlicesMap } from '../lib/mapSlicesToComponents'

// 1. Import your slice
import * as PageDataBodyAccordion from './PageBodyAccordion'
import * as PageDataBodyAnchor from './PageBodyAnchor'
import * as PageDataBodyCallToActionBoxes from './PageBodyCallToActionBoxes'
import * as PageDataBodyCallout from './PageBodyCallout'
import * as PageDataBodyCalloutBox from './PageBodyCalloutBox'
import * as PageDataBodyCenteredText from './PageBodyCenteredText'
import * as PageDataBodyCirclePromo from './PageBodyCirclePromo'
import * as PageDataBodyCognitoForm from './PageBodyCognitoForm'
import * as PageDataBodyFavoriteDishes from './PageBodyFavoriteDishes'
import * as PageDataBodyFeaturedImage from './PageBodyFeaturedImage'
import * as PageDataBodyFeaturedMenuItems from './PageBodyFeaturedMenuItems'
import * as PageBodyFooter from './PageBodyFooter'
import * as PageDataBodyFullWidthImage from './PageBodyFullWidthImage'
import * as PageBodyHeader from './PageBodyHeader'
import * as PageDataBodyHeroFloatingImage from './PageBodyHeroFloatingImage'
import * as PageDataBodyHeroImage from './PageBodyHeroImage'
import * as PageDataBodyHeroSideImage from './PageBodyHeroSideImage'
import * as PageDataBodyHeroMenuItem from './PageBodyHeroMenuItem'
import * as PageDataBodyHeroNavigation from './PageBodyHeroNavigation'
import * as PageDataBodyHeroPhrases from './PageBodyHeroPhrases'
import * as PageDataBodyHeroPlates from './PageBodyHeroPlates'
import * as PageDataBodyHighlights from './PageBodyHighlights'
import * as PageDataBodyImage from './PageBodyImage'
import * as PageDataBodyLLContent from './PageBodyLLContent'
import * as PageDataBodyLLHero from './PageBodyLLHero'
import * as PageDataBodyLLPartners from './PageBodyLLPartners'
import * as PageDataBodyLLText from './PageBodyLLText'
import * as PageDataBodyLargePromo from './PageBodyLargePromo'
import * as PageBodyLocationDetails from './PageBodyLocationDetails'
import * as PageBodyLocationIntro from './PageBodyLocationIntro'
import * as PageBodyLocationStaticMap from './PageBodyLocationStaticMap'
import * as PageBodyLocations from './PageBodyLocations'
import * as PageDataBodyMenuItems from './PageBodyMenuItems'
import * as PageDataBodyNutritionFacts from './PageBodyNutritionFacts'
import * as PageDataBodyPartners from './PageBodyPartners'
import * as PageDataBodyPlateLunchDiagram from './PageBodyPlateLunchDiagram'
import * as PageDataBodyPromoBoxes from './PageBodyPromoBoxes'
import * as PageDataBodyPromos from './PageBodyPromos'
import * as PageDataBodyQuote from './PageBodyQuote'
import * as PageDataBodyRestaurantLocator from './PageBodyRestaurantLocator'
import * as PageDataBodyText from './PageBodyText'
import * as PageDataBodyVideos from './PageBodyVideos'
import * as PageBodySearch from './PageBodySearch'
import * as PageDataBodyCarousel from './PageBodyCarousel'
import * as PageDataBodySteps from './PageBodySteps'
import * as PageDataBodySwipeableBoxes from './PageBodySwipeableBoxes'
import * as PageDataBodyImageWithPromo from './PageBodyImageWithPromo'
import * as PageDataBodyHtml from './PageBodyHtml'

// 2. Add your slice
const slices = {
	PageDataBodyAccordion,
	PageDataBodyAnchor,
	PageDataBodyCallToActionBoxes,
	PageDataBodyCallout,
	PageDataBodyCalloutBox,
	PageDataBodyCenteredText,
	PageDataBodyCirclePromo,
	PageDataBodyCognitoForm,
	PageDataBodyFavoriteDishes,
	PageDataBodyFeaturedImage,
	PageDataBodyFeaturedMenuItems,
	PageBodyFooter,
	PageDataBodyFullWidthImage,
	PageBodyHeader,
	PageDataBodyHeroFloatingImage,
	PageDataBodyHeroImage,
	PageDataBodyHeroSideImage,
	PageDataBodyHeroMenuItem,
	PageDataBodyHeroNavigation,
	PageDataBodyHeroPhrases,
	PageDataBodyHeroPlates,
	PageDataBodyHighlights,
	PageDataBodyImage,
	PageDataBodyLLContent,
	PageDataBodyLLHero,
	PageDataBodyLLPartners,
	PageDataBodyLLText,
	PageDataBodyLargePromo,
	PageBodyLocationDetails,
	PageBodyLocationIntro,
	PageBodyLocationStaticMap,
	PageBodyLocations,
	PageDataBodyMenuItems,
	PageDataBodyNutritionFacts,
	PageDataBodyPartners,
	PageDataBodyPlateLunchDiagram,
	PageDataBodyPromoBoxes,
	PageDataBodyPromos,
	PageDataBodyQuote,
	PageDataBodyRestaurantLocator,
	PageDataBodyText,
	PageDataBodyVideos,
	PageBodySearch,
	PageDataBodyCarousel,
	PageDataBodySteps,
	PageDataBodySwipeableBoxes,
	PageDataBodyImageWithPromo,
	PageDataBodyHtml,
}

// 3. Add your slice fragment
export const fragment = graphql`
	fragment SlicesPageBody on PrismicPageDataBody {
		...PageBodyAccordion
		...PageBodyAnchor
		...PageBodyCallToActionBoxes
		...PageBodyCallout
		...PageBodyCalloutBox
		...PageBodyCenteredText
		...PageBodyCirclePromo
		...PageBodyCognitoForm
		...PageBodyFavoriteDishes
		...PageBodyFeaturedImage
		...PageBodyFeaturedMenuItems
		...PageBodyFullWidthImage
		...PageBodyHeroFloatingImage
		...PageBodyHeroImage
		...PageBodyHeroSideImage
		...PageBodyHeroMenuItem
		...PageBodyHeroNavigation
		...PageBodyHeroPhrases
		...PageBodyHeroPlates
		...PageBodyHighlights
		...PageBodyImage
		...PageBodyLLContent
		...PageBodyLLHero
		...PageBodyLLPartners
		...PageBodyLLText
		...PageBodyLargePromo
		...PageBodyMenuItems
		...PageBodyNutritionFacts
		...PageBodyPartners
		...PageBodyPlateLunchDiagram
		...PageBodyPromoBoxes
		...PageBodyPromos
		...PageBodyQuote
		...PageBodyRestaurantLocator
		...PageBodyText
		...PageBodyVideos
		...PageBodyCarousel
		...PageBodySteps
		...PageBodySwipeableBoxes
		...PageBodyImageWithPromo
		...PageBodyHtml

		# The following slices do not have fragments:
		#...PageBodyFooter
		#...PageBodyHeader
		#...PageBodyLocationDetails
		#...PageBodyLocationStaticMap
		#...PageBodyLocationIntro
		#...PageBodyLocations
		#...PageBodySearch
	}
`

export const slicesMap = reshapeSlicesMap(slices)
